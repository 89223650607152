import { LogsAnalysis, PopupNotification } from 'devlink'
import {
    useCreateLogsLineMutation,
    useCreateLogsUploadMutation,
} from 'entities/loganalysis/logsanalysis.queries'
import { LogsContext } from 'pages/logsanalysis/logsanalysis-page.model'
import { SliderRange } from 'shared/ui/slider'
import React, { useContext, useLayoutEffect, useState } from 'react'

type LogsAnalysisContainerProps = {
    isLoading?: boolean
}

export function LogsAnalysisContainer({
    isLoading = false,
}: LogsAnalysisContainerProps) {
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })

    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    const [file, setFile] = useState<File | null>(null)
    const [fileContent, setFileContent] = useState<string | null>(null)
    const [answer, setAnswer] = useState<string | null>(null)
    const [fileName, setFileName] = useState<string>('')
    const [displayAnswer, setDisplayAnswer] = useState(false)
    const [temperature, setTemperature] = useState(1)
    const [tokens, setTokens] = useState(170)
    const [n_ctx, setN_ctx] = useState(1024)
    const [selectedValue, setSelectedValue] = useState<number | null>(null)

    const { logsStore } = useContext(LogsContext)

    let logResponse = ''

    const {
        mutate: createLogsLine,
        isPending: isGeneratingLine,
        isSuccess: isSuccessCreateLine,
        isError: isErrorCreateLine,
        data: dataCreateLine,
        error: errorCreateLine,
    } = useCreateLogsLineMutation()

    const {
        mutate: createLogsUpload,
        isSuccess: isSuccessCreateUpload,
        isError: isErrorCreateUpload,
        error: errorCreateUpload,
        isPending: isGeneratingUpload,
    } = useCreateLogsUploadMutation(logsStore)

    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }

    const handleFileUpload = (uploadedFile: File) => {
        const fileExtension = uploadedFile.name.split('.').pop()
        const allowedExtensions = ['csv']

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFile(uploadedFile)
            setFileName(uploadedFile.name)

            createLogsUpload(
                {
                    logsUploadPDF: {
                        file: uploadedFile,
                        best_response: '',
                        scanned_PDF: false,
                    },
                },
                {
                    onSuccess: (data) => {
                        setFileContent(data?.best_response || '')
                        openPopup(
                            '\ue902',
                            'File uploaded successfully',
                            'You can now ask your question.'
                        )
                    },
                    onError: (error) => {
                        openPopup(
                            '\ue91e',
                            'Upload Error',
                            'Failed to upload the file. Please try again.'
                        )
                    },
                }
            )
        } else {
            openPopup(
                '\ue91e',
                'Invalid file type',
                'Choose a valid file type: CSV, with the right format (look at the template in the header).'
            )
        }
    }

    useLayoutEffect(() => {
        if (isSuccessCreateUpload) {
            console.log('File uploaded successfully', fileContent)
        }
        if (isErrorCreateUpload) {
            console.log('Error uploading file', errorCreateUpload)
        }
    }, [isSuccessCreateUpload, isErrorCreateUpload])

    const generateAnswer = async () => {
        logResponse = ''
        setAnswer('')
        if (fileName) {
            try {
                const fileNameWithoutExtension = fileName.replace(
                    /\.(csv)$/,
                    ''
                )
                createLogsLine({
                    logsLine: {
                        file_logs: fileNameWithoutExtension,
                        llama_load_params: {
                            n_gpu_layers: 24,
                            n_ctx: n_ctx,
                        },
                        llama_generation_params: {
                            max_tokens: tokens,
                            temperature: temperature,
                        },
                    },
                    onChunkReceived: (chunk: string) => {
                        const cleanedChunk = chunk.replace(/^"/, '')
                        logsStore.setState((state: any) => ({
                            ...state,
                            logsLines: [
                                ...state.logsLines,
                                {
                                    file_logs: fileNameWithoutExtension,
                                    log_result:
                                        state.logsLines[0] +
                                        cleanedChunk +
                                        '\n',
                                },
                            ],
                        }))
                        logResponse += cleanedChunk
                        setAnswer(logResponse)
                        setDisplayAnswer(true)
                    },
                })
            } catch (error) {
                openPopup(
                    '\ue91e',
                    'Error',
                    'An error occurred while generating the answer.'
                )
            }
        } else {
            openPopup(
                '\ue91e',
                'Incomplete Data',
                'Please ensure a file is uploaded and a question is provided.'
            )
        }
    }

    const handleDisplayAnswer = () => {
        if (!fileContent) {
            openPopup(
                '\ue91e',
                'No file uploaded',
                'Please upload a file to generate an answer.'
            )
            return
        }
        generateAnswer()
    }

    return (
        <>
            <LogsAnalysis
                fileName={fileName}
                isGeneratingLine={isGeneratingLine}
                isSuccessCreateLine={isSuccessCreateLine}
                isSuccessCreateUpload={isSuccessCreateUpload}
                n_ctx={n_ctx}
                setN_ctx={setN_ctx}
                rpCTXtoSelect={{
                    onChange: (value: number) => {
                        setN_ctx(value)
                    }
                }}
                rpSliderRangeTemperature={
                    <SliderRange
                        value={((temperature - 0.1) / 0.9) * 100}
                        onChange={(value: number) => {
                            setTemperature((value / 100) * 0.9 + 0.1)
                        }}
                        min={0}
                        max={200}
                    />
                }
                rpSliderRangeToken={
                    <SliderRange
                        value={((tokens - 64) / 192) * 100}
                        onChange={(value: number) => {
                            setTokens((value / 100) * 192 + 64)
                        }}
                        min={0}
                        max={100}
                    />
                }
                rpFileUpload={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        const input = document.createElement('input')
                        input.type = 'file'
                        input.accept = '.csv'
                        input.onchange = (e) => {
                            const target = e.target as HTMLInputElement
                            if (target.files) {
                                handleFileUpload(target.files[0])
                            }
                        }
                        input.click()
                    },
                    onDragOver: (e: React.DragEvent) => {
                        e.preventDefault()
                    },
                    onDrop: (e: React.DragEvent) => {
                        e.preventDefault()
                        const droppedFile = e.dataTransfer.files[0]
                        handleFileUpload(droppedFile)
                    },
                }}
                rpEnterButton={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        handleDisplayAnswer()
                    },
                }}
                result={
                    answer === '' ? (
                        <span className="flex-center justify-center text-color-orange padding-vertical padding-xlarge">
                            Generating logs...
                        </span>
                    ) : displayAnswer ? (
                        answer
                    ) : (
                        <div className='flex-col justify-center'>
                            <span className="margin-top margin-large">
                                Please upload a .csv file and click on the button to see the answer here.
                            </span>
                            <span className="text-color-orange margin-top margin-small">
                                Make sure to provide the same format as the provided log template in the header!
                            </span>
                        </div>
                    )
                }
            />
                                    <PopupNotification
                                        visibility={isModalPopupOpen}
                                        rpClosePopUp={{
                                            onClick: (e: React.MouseEvent) => {
                                                e.preventDefault()
                                                setIsModalPopupOpen?.(false)
                                            },
                                        }}
                                        rpInfoText={{
                                            header: popupElements.header,
                                            body: popupElements.body,
                                            footer: '',
                                        }}
                                        rpIcon={popupElements.icon}
                                    />
        </>
    )
}
