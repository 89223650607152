import {
    CreateTeam,
    DataTeam,
    DisplayUsers,
    ModifyTeam,
    RemoveTeam,
    SectionClosed,
} from 'devlink'
import { sessionQueries } from 'entities/session'
import { Keys, sessionStore } from 'entities/session/session.model'
import { AdminContext } from 'pages/admin/admin-page.model'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'

type SectionAdminContainerProps = {
    selectedTeam: Team
    selectedKeys: Keys
}

type MultiSelectProps = {
    value: string
    label: string
}

export type Team = {
    name: string
    is_owner: number
    is_admin: number
    can_manage_models: number
    can_manage_npcs: number
    can_manage_datasets: number
    can_download_ariel: number
    can_download_diagen: number
    can_download_gepetto: number
    valid_until: string
    valid_from: string
}

export function SectionAdminContainer({
    selectedTeam,
    selectedKeys,
}: SectionAdminContainerProps) {
    const {
        adminStore,
        setIsConfirmationDeleteOpen,
        setIsConfirmationCreateOpen,
        setCreateValidFrom,
        setCreateValidUntil,
        setIsConfirmationWarningOpen,
        setCreateTeamName,
        setIsConfirmationModifyOpen,
        updateValidFrom,
        setUpdateValidFrom,
        updateValidUntil,
        setUpdateValidUntil,
    } = useContext(AdminContext)
    const [teamOptions, setTeamOptions] = useState<MultiSelectProps[]>([])
    const [teams, setTeams] = useState<any[]>([])
    const [valid_from, setValidFrom] = useState<string>('')
    const [valid_until, setValidUntil] = useState<string>('')
    const [users, setUsers] = useState<any[]>([])
    const [teamName, setTeamName] = useState<string>('')
    const [actualValidFrom, setActualValidFrom] = useState<any>(
        sessionStore.getState().keys?.diagen.valid_from
    )
    const [actualValidUntil, setActualValidUntil] = useState<any>(
        sessionStore.getState().keys?.diagen.valid_until
    )
    const [createSection, setCreateSection] = useState(false)
    const [modifySection, setModifySection] = useState(false)
    const [getDataTeam, setDataTeam] = useState(false)

    const {
        mutate: getUsers,
        isPending: getUsersPending,
        data: responseUsers,
        isSuccess: isSuccessUsers,
        isError: isErrorUsers,
        error: errorUsers,
    } = sessionQueries.useGetUsersMutation()

    useEffect(() => {
        const now = new Date()
        const validFromDate = now.toISOString().split('T')[0]
        const validUntilDate = new Date(now.setDate(now.getDate() + 30))
            .toISOString()
            .split('T')[0]
        setValidFrom(validFromDate)
        setValidUntil(validUntilDate)
    }, [])

    useLayoutEffect(() => {
        if (isSuccessUsers) {
            setUsers(
                Object.keys(responseUsers).map((key: string) => {
                    const user = responseUsers[key]
                    if (!user.email) {
                        user.email = 'unknown email adress'
                    }
                    return user
                })
            )
        }
        if (isErrorUsers) {
            console.log('errorUsers', errorUsers)
            setUsers([])
        }
    }, [isSuccessUsers, isErrorUsers])

    // load the Admin store when the component mounts
    useLayoutEffect(() => {
        adminStore.getState().load()
        getUsers()
    }, [])

    // save the Admin store when the component unmounts
    useLayoutEffect(() => {
        return () => {
            adminStore.getState().save()
        }
    }, [])

    useEffect(() => {
        const updateTeamData = () => {
            const updatedTeams = teams.map((team) => {
                if (team.name === selectedTeam?.name) {
                    return {
                        ...team,
                        valid_from: valid_from,
                        valid_until: valid_until,
                    }
                }
                return team
            })
            setTeams(updatedTeams)
        }

        if (valid_from && valid_until) {
            updateTeamData()
        } else if (!valid_from && valid_until) {
            updateTeamData()
        } else if (!valid_until && valid_from) {
            updateTeamData()
        }
    }, [valid_from, valid_until])

    useLayoutEffect(() => {
        getUsers()
        const currentState = sessionStore.getState()
        const filteredTeams =
            currentState.teams?.filter(
                (team) => team.name !== selectedTeam?.name
            ) || []
        setTeamOptions(
            filteredTeams.map((team, index) => ({
                label: team.name,
                value: index.toString(),
            }))
        )
    }, [selectedTeam])

    useLayoutEffect(() => {
        const currentState = sessionStore.getState()
        setActualValidFrom(currentState.keys?.diagen.valid_from)
        setActualValidUntil(currentState.keys?.diagen.valid_until)
    }, [selectedKeys])

    return (
        <>
            <DisplayUsers
                users={users}
                teamOptions={teamOptions}
                disabled={false}
                loading={getUsersPending}
            />
            {getDataTeam ? (
                <DataTeam
                    selectedKeys={selectedKeys}
                    selectedTeam={selectedTeam}
                />
            ) : (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setDataTeam(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'Data Usage Team'}
                    subtitle={'View the data usage of a team'}
                />
            )}
            {createSection ? (
                <CreateTeam
                    rdTeam={{
                        value: teamName,
                        onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => {
                            const newTeamName = e.target.value
                            setTeamName(newTeamName)
                        },
                    }}
                    validFrom={{
                        value: valid_from,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            const newValidFrom = e.target.value
                            setValidFrom(newValidFrom)
                        },
                    }}
                    validUntil={{
                        value: valid_until,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            const newValidUntil = e.target.value
                            setValidUntil(newValidUntil)
                        },
                    }}
                    rdButtonCreate={{
                        className:
                            !teamName || teamName === ''
                                ? 'button is-disabled'
                                : 'button',
                        disabled: !teamName || teamName === '',
                        onClick: () => {
                            if (!teamName || teamName === '') {
                                setIsConfirmationWarningOpen?.(true)
                                setIsConfirmationCreateOpen?.(false)
                            } else {
                                setCreateTeamName?.(teamName)
                                setCreateValidFrom?.(valid_from)
                                setCreateValidUntil?.(valid_until)
                                setIsConfirmationCreateOpen?.(true)
                            }
                        },
                    }}
                    onClose={{
                        onClick: () => {
                            setCreateSection(false)
                        },
                    }}
                />
            ) : (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setCreateSection(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'Create Team'}
                    subtitle={
                        'Create a new team by setting name and a date range for the API keys'
                    }
                />
            )}
            {modifySection ? (
                <ModifyTeam
                    actualValidFrom={actualValidFrom}
                    actualValidUntil={actualValidUntil}
                    validFrom={{
                        value: valid_from,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            const modifiedValidFrom = e.target.value
                            setValidFrom?.(modifiedValidFrom)
                        },
                    }}
                    validUntil={{
                        value: valid_until,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            const modifyValidUntil = e.target.value
                            setValidUntil?.(modifyValidUntil)
                        },
                    }}
                    onClose={{
                        onClick: () => {
                            setModifySection(false)
                        },
                    }}
                    rdButtonModify={{
                        className: 'button',
                        onClick: () => {
                            if (
                                !updateValidFrom ||
                                (updateValidFrom === '' && !updateValidUntil) ||
                                updateValidUntil === ''
                            ) {
                                setIsConfirmationModifyOpen?.(false)
                                setUpdateValidFrom?.(valid_from)
                                setUpdateValidUntil?.(valid_until)
                            } else {
                                setIsConfirmationModifyOpen?.(true)
                            }
                        },
                    }}
                />
            ) : (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setModifySection(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'Modify API Keys Access'}
                    subtitle={
                        'Modify an existing team by setting a new date range for the API keys'
                    }
                />
            )}
            <RemoveTeam
                rdButtonRemove={{
                    onClick: () => {
                        setIsConfirmationDeleteOpen?.(true)
                    },
                }}
            />
        </>
    )
}
