import React, { useState, useEffect } from 'react';
import * as _Builtin from '../_Builtin'
import * as _interactions from '../interactions'
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'

export function VideoAndEvilLordDemo({
    as: _Component = _Builtin.Section,
    rpButtonEvilLordDownload,
    rpUserAccessRights
}) {

    const videos = [
        {
            url: "//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqKQHIFlj06M%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqKQHIFlj06M&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqKQHIFlj06M%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube",
            title: "X&Immersion - ANIMATION TOOLS",
        },
        {
            url: "https://xandimmersion.s3.us-east-2.amazonaws.com/CREATE+APP/animation.mp4",
            title: "X&Immersion - ANIMATION TOOLS",
        },
        {
            url: "//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FE0TonOl1GX4%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DE0TonOl1GX4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FE0TonOl1GX4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube",
            title: "X&Immersion - ANIMATION TOOLS",
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
        const isLastVideo = currentIndex === videos.length - 1;
        const newIndex = isLastVideo ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToPrevious = () => {
        const isFirstVideo = currentIndex === 0;
        const newIndex = isFirstVideo ? videos.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                goToPrevious();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft') {
                goToPrevious();
            } else if (event.key === 'ArrowRight') {
                goToNext();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex]);

    return (
        <_Component
            className="section"
            grid={{
                type: 'section',
            }}
            tag="section"
        >
            <_Builtin.Block className='padding-horizontal padding-small container-large '>
                <_Builtin.Block className="grid-2col" tag="div">
                    <_Builtin.Block tag="div">
                        <_Builtin.Block className="light-border rounded-8" tag="div">
                                <_Builtin.Block className="full-cover video-container overflow-hidden" tag="div">
                                    <_Builtin.Video
                                        options={{
                                            height: '152',
                                            width: '270',
                                            title: videos[currentIndex].title,
                                            url: videos[currentIndex].url,
                                        }}
                                    />
                                </_Builtin.Block>
                        </_Builtin.Block>
                        <_Builtin.Block className="flex-center margin-top margin-tiny" tag="div">
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                onClick={goToPrevious}
                                className="cursor-pointer margin-right margin-small arrow-carousel"
                            />
                            <FontAwesomeIcon
                                icon={faArrowRight}
                                onClick={goToNext}
                                className='cursor-pointer arrow-carousel '
                            />
                        </_Builtin.Block>
                    </_Builtin.Block>
                    {/* Conteneur parent en grille */}
                    <_Builtin.Block className="encarts-container gap-large" tag="div">  
                            {/* Partie principale */}
                            <_Builtin.Block className="evillordcontainer rounded-8" tag="div">
                                <_Builtin.Image
                                    className="evillordimage"
                                    width="auto"
                                    height="auto"
                                    alt=""
                                    src="/images/evillord.png"
                                />

                                {/* Partie droite : Contenu textuel */}
                                <_Builtin.Block className="content-container padding padding-medium" tag="div">
                                    <_Builtin.Paragraph className="text-weight-semibold text-size-large flex-center">
                                        {'Evil Lord Demo'}
                                    </_Builtin.Paragraph>
                                    <_Builtin.Paragraph className="text-color-light-grey flex-center">
                                        {'Geppetto, Ariel and Diagen Package'}
                                    </_Builtin.Paragraph>
                                    {rpUserAccessRights && rpUserAccessRights.can_download_diagen && rpUserAccessRights.can_download_ariel && rpUserAccessRights.can_download_gepetto === 1 ? (
                                        <>
                                            {/* Boutons de téléchargement */}
                                            <_Builtin.Block className="flex-center margin margin-xxlarge gap-8px" tag="div">

                                                <_Builtin.Paragraph className="cursor-pointer margin-right margin-large" {...rpButtonEvilLordDownload}>
                                                    {'Evil Lord Demo'}
                                                </_Builtin.Paragraph>
                                                <_Builtin.Link className="button is-secondary" button={false} {...rpButtonEvilLordDownload}>
                                                    <_Builtin.Block className="slot-button" tag="div">
                                                        <ButtonIcon buttonIconText="" />
                                                        <ButtonText buttonText="Download" />
                                                    </_Builtin.Block>
                                                </_Builtin.Link>
                                            </_Builtin.Block>
                                        </>
                                    ) : (
                                        /* Message si pas d'accès */
                                        <_Builtin.Block className="textposition margin-vertical margin-large" tag="div">
                                            <_Builtin.Paragraph className="text-color-light-grey">
                                                {'You want to get access to Evil Lord demo?'}
                                            </_Builtin.Paragraph>
                                            <_Builtin.Paragraph className="text-color-light-grey">
                                                {'Contact us at '}
                                                <a href="mailto:bastien.s@xandimmersion.com">
                                                    bastien.s@xandimmersion.com
                                                </a>
                                                {' and someone from our team will answer you!'}
                                            </_Builtin.Paragraph>
                                        </_Builtin.Block>
                                    )}
                                </_Builtin.Block>
                            </_Builtin.Block>
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
