import { sessionModel } from 'entities/session'
import { sessionStore } from 'entities/session/session.model'
import { dialogBaseUrl1, dialogBaseUrl2 } from 'shared/api/ximmersion'
import { createFormMutation, createJsonMutation, createJsonQuery } from 'shared/lib/fetch'
import { zodContract } from 'shared/lib/zod'
import { CallStateChangesResponseSchema, CreatePromptResponseSchema, CreateTopicResponseSchema, DiagenEventResponseSchema, InitSessionResponseSchema, UpdateHistoryResponseSchema, UploadDiagenFilesResponseSchema, getEventsResponseSchema, getTagsResponseSchema } from './dialog-line.contracts'
import { CreateCallStateChanges, CreateDiagenEvent, CreateDialogLineDto, CreateTopicDetection, CreateUploadDiagenFilesSchema, UpdateHistory } from './dialog-line.types'


type DialogUrl = 'url1' | 'url2'

type PromptDataType = {
  npc_name: string;
  player_name: string;
  local_exec: boolean;
};

export async function createInitSessionMutation() {
  return createJsonMutation({
    request: {
      url: dialogBaseUrl1('/init_session'),
      method: 'POST',
      headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
    },
    response: {
      contract: zodContract(InitSessionResponseSchema),
    },
  })
}

export async function CreateGetTagsMutation() {
  const state = sessionStore.getState();
  const session_id = state.session_id;

  return createJsonQuery({
    request: {
        url: dialogBaseUrl1(`/get-tags?session_id=${session_id}`),
        method: 'GET',
        headers: { ...sessionModel.authorizationHeader({ system: 'diagen' })  },
    },
    response: {
        contract: zodContract(getTagsResponseSchema),
    },
  })}

  export async function CreateGetEventsMutation() {
    const state = sessionStore.getState();
    const session_id = state.session_id;
  
    return createJsonQuery({
      request: {
          url: dialogBaseUrl1(`/get-diagenEvents?session_id=${session_id}`),
          method: 'GET',
          headers: { ...sessionModel.authorizationHeader({ system: 'diagen' })  },
      },
      response: {
          contract: zodContract(getEventsResponseSchema),
      },
    })}

export async function CreateUploadDiagenFilesMutation(params: {
  diagenuploadPDF: CreateUploadDiagenFilesSchema
}) {
  let body: FormData | string | undefined = undefined;
  body = new FormData();
  const state = sessionStore.getState();
  if (state.session_id) {
    body.append('session_id', state.session_id);
  }
  if (params.diagenuploadPDF.topics) {
    body.append('topics', params.diagenuploadPDF.topics);
  }
  if (params.diagenuploadPDF.diagenEvents) {
    body.append('diagenEvents', params.diagenuploadPDF.diagenEvents);
  }
  if (params.diagenuploadPDF.characterInformation) {
    body.append('characterInformation', params.diagenuploadPDF.characterInformation);
  }
  if (params.diagenuploadPDF.tagsWeight) {
    body.append('tagsWeight', params.diagenuploadPDF.tagsWeight);
  }

  return createFormMutation({
    request: {
      url: dialogBaseUrl1(`/upload-diagen-files`),
      method: 'POST',
      headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
      body: body,
    },
    response: {
      contract: zodContract(UploadDiagenFilesResponseSchema),
    },
  })
}


export async function createCallStateChangesMutation(params: {
  stateChanges: CreateCallStateChanges;
  dialogUrl?: DialogUrl;
}): Promise<void> {
  const state = sessionStore.getState();

  await createJsonMutation({
    request: {
      url: dialogBaseUrl1('/call-stateChanges'),
      method: 'POST',
      headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
      body: JSON.stringify({
        ...params.stateChanges,
        session_id: state.session_id,
      }),
    },
    response: {
      contract: zodContract(CallStateChangesResponseSchema),
    },
  });
}

    export async function createDiagenEventMutation(params: {
      diagenEvents: CreateDiagenEvent;
      dialogUrl?: DialogUrl;
    }) {
      const state = sessionStore.getState();
    
      return createJsonMutation({
        request: {
          url: dialogBaseUrl1('/call-diagenEvent'),
          method: 'POST',
          headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
          body: JSON.stringify({
            ...params.diagenEvents,
            session_id: state.session_id,
          }),
        },
        response: {
          contract: zodContract(DiagenEventResponseSchema),
        },
      });
    }

    export async function createUpdateHistoryMutation(params: {
      updateHistories: UpdateHistory;
      dialogUrl?: DialogUrl;
    }): Promise<void> {
      const state = sessionStore.getState();
    
      await createJsonMutation({
        request: {
          url: dialogBaseUrl1('/update-history'),
          method: 'POST',
          headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
          body: JSON.stringify({
            ...params.updateHistories,
            session_id: state.session_id,
          }),
        },
        response: {
          contract: zodContract(UpdateHistoryResponseSchema),
        },
      });
    }


    export async function createTopicDetectionMutation(params: {
      topicDetection: CreateTopicDetection
      dialogUrl?: DialogUrl
    }) {
        const state = sessionStore.getState();
        return createJsonMutation({
          request: {
            url: dialogBaseUrl1('/topic-detection-v3'),
            method: 'POST',
            headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
            body: JSON.stringify({
              ...params.topicDetection,
              session_id: state.session_id,
            }),
          },
          response: {
            contract: zodContract(CreateTopicResponseSchema),
          },
        });
      }

export async function createPromptMutation(params: {
  promptData: PromptDataType}) {
    const state = sessionStore.getState();
    return createJsonMutation({
      request: {
        url: dialogBaseUrl1('/create_prompt'),
        method: 'POST',
        headers: { ...sessionModel.authorizationHeader({ system: 'diagen' }) },
        body: JSON.stringify({
          ...params.promptData,
          session_id: state.session_id,
        }),
      },
      response: {
        contract: zodContract(CreatePromptResponseSchema),
      },
    });
  }


export async function createDialogLineMutation(params: {
  dialogLine: CreateDialogLineDto
  dialogUrl?: DialogUrl
  onChunkReceived: (chunk: string) => void
}
): Promise<void> {
  const baseUrl =
    params.dialogUrl === 'url1' ? dialogBaseUrl1 : dialogBaseUrl2
    const state = sessionStore.getState();

  const response = await fetch(baseUrl(`/generate-stream`), {
    method: "POST",
    headers: {
      Authorization: sessionModel.authorizationHeader({ useToken: false, system: 'diagen' }).Authorization || "Api-Key",
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...params.dialogLine,
      session_id: state.session_id,
    }),  
  });
  if (!response.body) {
    throw new Error("No response body found");
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder("utf-8");
  let done = false;
  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    if (value) {
      params.onChunkReceived(decoder.decode(value));
    }
  }
}
