import {
    PopupConfirmCreate,
    PopupConfirmDelete,
    PopupConfirmWarning,
    PopupConfirmModify,
    PopupNotification,
    PopupConfirmAdd,
    PopupConfirmAccessRights,
} from 'devlink'
import {
    useAddMemberMutation,
    useCreateTeamMutation,
    useDeleteTeamMutation,
    useEditUserMutation,
    useModifyTeamMutation,
} from 'entities/admin/admin.queries'
import { characterStore } from 'entities/character/character.model'
import { AdminContext } from 'pages/admin/admin-page.model'
import React, {
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react'
import { sessionStore } from '../../entities/session/session.model'
import { useNavigate } from 'react-router-dom'

export function PopupAdminConfirmDeleteContainer() {

    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    
    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }

    
    const { isConfirmationDeleteOpen, setIsConfirmationDeleteOpen } =
        useContext(AdminContext)
    const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
        characterStore.getState().currentTeam?.name || null
    )

    const {
        mutate: deleteTeam,
        isError: isErrorDeleteTeam,
        data: responseDeleteTeam,
        isSuccess: isSuccessDeleteTeam,
        error: errorDeleteTeam,
    } = useDeleteTeamMutation()

    useLayoutEffect(() => {
        if (isSuccessDeleteTeam) {
            console.log('Delete team successful', responseDeleteTeam)
            const data = sessionStore
                .getState()
                .teams?.filter((team: any) => team.name !== selectedTeam)
            if (data) {
                // set the current team to the first team in the list
                const currentState = sessionStore.getState()
                characterStore
                    .getState()
                    .updateCurrentTeam(
                        currentState.teams?.find((t) => t.name !== selectedTeam)
                    )
                sessionStore.getState().updateTeams(data)
            }
            openPopup(
                '\ue902',
                'Success',
                `Team '${selectedTeam}' has been deleted successfully.`
            )
        }
        if (isErrorDeleteTeam) {
            openPopup(
                '\ue91e',
                'Error',
                `There was an error deleting Team '${selectedTeam}'.`
            )
            console.log('errorDeleteTeam', errorDeleteTeam)
        }
    }, [isSuccessDeleteTeam, isErrorDeleteTeam])

    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (selectedTeam && state.currentTeam?.name !== selectedTeam) {
                setSelectedTeam(state.currentTeam?.name)
            }
        })
    }, [selectedTeam])

    const handleClosePopup = useCallback(() => {
        setIsConfirmationDeleteOpen?.(false)
    }, [setIsConfirmationDeleteOpen])

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])


    function callDeleteTeam(name: string) {
        console.log('Delete team')
        deleteTeam({ name: name })
    }

    return (
        <>
            <PopupConfirmDelete
                visibility={isConfirmationDeleteOpen}
                rpClosePopup={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationDeleteOpen?.(false)
                    },
                }}
                rpButtonCancel={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationDeleteOpen?.(false)
                    },
                }}
                rpButtonConfirm={{
                    className: 'button is-small is-red',
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        if (!selectedTeam) return
                        callDeleteTeam(selectedTeam)
                    },
                }}
                title="Delete Team"
                content={`You are about to delete this team. All data will be removed.`}
                content2={`Are you sure you want to delete Team '${selectedTeam}'?`}
            />
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

export function PopupAdminWarningContainer() {

    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    
    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }
    
    const { isConfirmationWarningOpen, setIsConfirmationWarningOpen } =
        useContext(AdminContext)

    const handleClosePopup = useCallback(() => {
        setIsConfirmationWarningOpen?.(false)
    }, [setIsConfirmationWarningOpen])

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    return (
        <>
            <PopupConfirmWarning
                visibility={isConfirmationWarningOpen}
                rpClosePopup={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationWarningOpen?.(false)
                    },
                }}
                rpButtonCancel={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationWarningOpen?.(false)
                    },
                }}
                title="Warning"
                content={`You need to give your team a name.`}
                content2={`Try again!`}
            />
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

export function PopupAdminConfirmCreateContainer() {

    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    
    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }
    
    const {
        isConfirmationCreateOpen,
        setIsConfirmationCreateOpen,
        createTeamName,
        createValidUntil,
        createValidFrom,
    } = useContext(AdminContext)

    const navigate = useNavigate()

    const handleClosePopup = useCallback(() => {
        setIsConfirmationCreateOpen?.(false)
    }, [setIsConfirmationCreateOpen])

    const {
        mutate: createTeam,
        isError: isErrorCreateTeam,
        data: responseCreateTeam,
        isSuccess: isSuccessCreateTeam,
        error: errorCreateTeam,
    } = useCreateTeamMutation()

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    useLayoutEffect(() => {
        if (isSuccessCreateTeam) {
            console.log('Create team successful', responseCreateTeam)
            const data = sessionStore.getState().teams
            if (data) {
                data?.push({
                    name: createTeamName,
                    is_owner: 1,
                    is_admin: 1,
                    can_manage_models: 1,
                    can_manage_npcs: 1,
                    can_manage_datasets: 1,
                    valid_until: createValidUntil + ' 00:00:00',
                    valid_from: createValidFrom + ' 00:00:00',
                    can_download_gepetto: 1,
                    can_download_ariel: 1,
                    can_download_diagen: 1,
                })

                data.sort((a: any, b: any) => {
                    return a.name.localeCompare(b.name)
                })
                sessionStore.getState().updateTeams(data)
                window.location.reload()
            }
            openPopup(
                '\ue902',
                'Success',
                `Team '${createTeamName}' has been created successfully. Go on the right dropdown to select it.`
            )
        }
        if (isErrorCreateTeam) {
            openPopup(
                '\ue91e',
                'Error',
                `There was an error creating Team '${createTeamName}'. Name probably already exists.`
            )
            console.log('errorCreateTeam', errorCreateTeam)
        }
    }, [
        isSuccessCreateTeam,
        isErrorCreateTeam,
        createTeamName,
        createValidFrom,
        createValidUntil,
    ])

    const handleCreateTeam = () => {
        createTeam({
            name: createTeamName,
            valid_from: createValidFrom + ' 00:00:00',
            valid_until: createValidUntil + ' 00:00:00',
            allow_admin_creation: true,
        })
    }

    return (
        <>
            <PopupConfirmCreate
                visibility={isConfirmationCreateOpen}
                rpClosePopup={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationCreateOpen?.(false)
                    },
                }}
                rpButtonCancel={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationCreateOpen?.(false)
                    },
                }}
                rpButtonConfirm={{
                    className: 'button is-small is-primary',
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        handleCreateTeam()
                    },
                }}
                title="Create Team"
                content={`You are about to create a new team.`}
                content2={`Are you sure you want to create Team '${createTeamName}'?`}
            />
        </>
    )
}

interface PopupAdminConfirmModifyTeamContainerProps {
    selectedTeam: string
}

export function PopupAdminConfirmModifyTeamContainer({
    selectedTeam,
}: PopupAdminConfirmModifyTeamContainerProps) {

    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    
    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }
    
    const {
        isConfirmationModifyOpen,
        setIsConfirmationModifyOpen,
        updateValidFrom,
        updateValidUntil,
    } = useContext(AdminContext)

    const handleClosePopup = useCallback(() => {
        setIsConfirmationModifyOpen?.(false)
    }, [setIsConfirmationModifyOpen])

    const {
        mutate: modifyTeam,
        isPending: modifyTeamPending,
        isError: isErrorModifyTeam,
        data: responseModifyTeam,
        isSuccess: isSuccessModifyTeam,
        error: errorModifyTeam,
    } = useModifyTeamMutation()

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    useLayoutEffect(() => {
        if (isSuccessModifyTeam) {
            const keys = sessionStore.getState().keys
            if (keys) {
                keys.diagen.valid_from = updateValidFrom + ' 00:00:00'
                keys.gepetto.valid_from = updateValidFrom + ' 00:00:00'
                keys.ariel.valid_from = updateValidFrom + ' 00:00:00'
                keys.gepetto.valid_until = updateValidUntil + ' 00:00:00'
                keys.ariel.valid_until = updateValidUntil + ' 00:00:00'
                keys.diagen.valid_until = updateValidUntil + ' 00:00:00'
                console.log('Keys before update:', sessionStore.getState().keys)
                sessionStore.getState().updateKeys(keys)
                console.log('Keys after update:', sessionStore.getState().keys)
            }
            openPopup(
                '\ue902',
                'Success',
                `Team '${selectedTeam}' API Keys have been modified successfully.`)
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        }
        if (isErrorModifyTeam) {
            openPopup(
                '\ue91e',
                'Error',
                `There was an error modifying Team '${selectedTeam}' API Keys. Check if the dates are correct.`)
            console.log('errorModifyTeam', errorModifyTeam)
        }
    }, [isSuccessModifyTeam, isErrorModifyTeam])

    function modifyTeamData() {
        modifyTeam({
            name: selectedTeam,
            valid_from: updateValidFrom + ' 00:00:00',
            valid_until: updateValidUntil + ' 00:00:00',
        })
    }

    return (
        <>
            <PopupConfirmModify
                visibility={isConfirmationModifyOpen}
                rpClosePopup={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationModifyOpen?.(false)
                    },
                }}
                rpButtonCancel={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationModifyOpen?.(false)
                    },
                }}
                rpButtonConfirm={{
                    className: 'button is-small is-primary',
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        modifyTeamData()
                    },
                }}
                title="Modify Team"
                content={`You are about to modify this team.`}
                content2={`Are you sure you want to modify Team '${selectedTeam}'?`}
            />
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

interface PopupAdminConfirmAccessRightsContainerProps {
    selectedTeam: string
}

export function PopupAdminConfirmAccessRightsContainer({
    selectedTeam,
}: PopupAdminConfirmAccessRightsContainerProps) {

    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    
    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }

    
    // const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
    //     characterStore.getState().currentTeam?.name || null
    // )
    const {
        isConfirmAccessRightsOpen,
        setIsConfirmAccessRightsOpen,
        userAccessRights,
        updateUserName,
    } = useContext(AdminContext)

    const handleClosePopup = useCallback(() => {
        setIsConfirmAccessRightsOpen?.(false)
    }, [setIsConfirmAccessRightsOpen])

    const {
        mutate: editUser,
        isPending: editUserPending,
        isError: isErrorEditUser,
        data: responseEditUser,
        isSuccess: isSuccessEditUser,
        error: errorEditUser,
    } = useEditUserMutation()

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    useLayoutEffect(() => {
        if (isSuccessEditUser) {
            openPopup(
                '\ue902',
                'Success',
                `User '${updateUserName}' has been updated successfully.`
            )
            console.log('Edit User successful', responseEditUser)
        }
        if (isErrorEditUser) {
            openPopup(
                '\ue91e',
                'Error',
                `There was an error updating user '${updateUserName}'.`
            )
            console.log('errorEditUser', errorEditUser)
        }
    }, [isSuccessEditUser, isErrorEditUser])

    function callChangeAccessRights(
        uid: string,
        is_admin: boolean,
        is_owner: boolean,
        can_manage_models: boolean,
        can_manage_npcs: boolean,
        can_manage_datasets: boolean,
        can_download_gepetto: boolean,
        can_download_ariel: boolean,
        can_download_diagen: boolean,
        valid_until: string,
        valid_from: string,
        team: string
    ) {
        console.log('Edit User')
        editUser({
            uid: uid,
            is_admin: is_admin,
            is_owner: is_owner,
            can_manage_models: can_manage_models,
            can_manage_npcs: can_manage_npcs,
            can_manage_datasets: can_manage_datasets,
            can_download_gepetto: can_download_gepetto,
            can_download_ariel: can_download_ariel,
            can_download_diagen: can_download_diagen,
            valid_until: valid_until,
            valid_from: valid_from,
            team: team,
        })
    }

    return (
        <>
            <PopupConfirmAccessRights
                visibility={isConfirmAccessRightsOpen}
                rpClosePopup={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmAccessRightsOpen?.(false)
                    },
                }}
                rpButtonCancel={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmAccessRightsOpen?.(false)
                    },
                }}
                rpButtonConfirm={{
                    className: 'button is-small is-primary',
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        if (!selectedTeam || !updateUserName) return
                        callChangeAccessRights(
                            updateUserName || '',
                            userAccessRights.is_admin,
                            userAccessRights.is_owner,
                            userAccessRights.can_manage_models,
                            userAccessRights.can_manage_npcs,
                            userAccessRights.can_manage_datasets,
                            userAccessRights.can_download_gepetto,
                            userAccessRights.can_download_ariel,
                            userAccessRights.can_download_diagen,
                            userAccessRights.valid_until,
                            userAccessRights.valid_from,
                            selectedTeam || ''
                        )
                    },
                }}
                title="Edit User"
                content={`You are about to change User Attributes.`}
                content2={`Are you sure you want to Edit the user '${updateUserName}'?`}
            />
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

export function PopupAdminConfirmAddMemberTeamsContainer() {
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    
    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }

    
    const {
        isConfirmationAddOpen,
        setIsConfirmationAddOpen,
        targetTeamsList,
        updateUserName,
    } = useContext(AdminContext)

    const handleClosePopup = useCallback(() => {
        setIsConfirmationAddOpen?.(false)
    }, [setIsConfirmationAddOpen])

    const {
        mutate: addUser,
        isPending: addUserPending,
        isError: isErrorAddUser,
        data: responseAddUser,
        isSuccess: isSuccessAddUser,
        error: errorAddUser,
    } = useAddMemberMutation()

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClosePopup()
            }
        }

        document.addEventListener('keydown', handleEscKey)

        return () => {
            document.removeEventListener('keydown', handleEscKey)
        }
    }, [handleClosePopup])

    useLayoutEffect(() => {
        if (isSuccessAddUser) {
            openPopup(
                '\ue902',
                'Success',
                `User '${updateUserName}' has been added to the selected teams.`
            )
            console.log('Edit User successful', responseAddUser)
        }
        if (isErrorAddUser) {
            openPopup(
                '\ue91e',
                'Error',
                `There was an error adding user '${updateUserName}' to the selected teams.`
            )
            console.log('errorEditUser', errorAddUser)
        }
    }, [isSuccessAddUser, isErrorAddUser])

    return (
        <>
            <PopupConfirmCreate
                visibility={isConfirmationAddOpen}
                rpClosePopup={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationAddOpen?.(false)
                    },
                }}
                rpButtonCancel={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsConfirmationAddOpen?.(false)
                    },
                }}
                rpButtonConfirm={{
                    className: 'button is-small is-primary',
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        if (!targetTeamsList || !updateUserName) return
                        for (let i = 0; i < targetTeamsList.length; i++) {
                            addUser({
                                uid: updateUserName,
                                team: targetTeamsList[i],
                            })
                        }
                    },
                }}
                title="Add User Teams"
                content={`You are about to add the user to other teams.`}
                content2={`Are you sure you want grant user '${updateUserName}' access to other teams?`}
            />
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}
