import { rollModel } from 'entities/roll'
import { StoreApi } from 'zustand'

export async function generateLine(
    createDialogLine: any,
    rollStore: StoreApi<rollModel.State>,
    params: {
        maxToken: number
        temperature: number
        toxicity: number
        presets: string
        npc_name: string
        character: string
        startState: string,
        question: string
        language: string
        prompt_name: string
    }
) {
    const state = rollStore.getState()

    const randomVariance =  Math.random() * 0.3;
    const randomVarianceTOXICITY =  Math.random() * 0.3;
    const randomVarianceTOKEN = Math.random(); 

    const newTemperature = params.temperature + randomVariance; 
    const newMaxToken = Math.round(params.maxToken + randomVarianceTOKEN);
    const newToxicity = params.temperature + randomVarianceTOXICITY; 

    const newLineParams = {
        maxToken: newMaxToken,
        question: params.question || '',
        npc_name: params.npc_name || '',
        character: params.character || '',
        startState: params.startState || '' + params.presets || '',
        temperature: Math.max(0, Math.min(2, newTemperature)), 
        toxicity: Math.max(0, Math.min(1, newToxicity)), 
        language: params.language,
        prompt_name: params.prompt_name
    }
    await wait(1000);
    createDialogLine({ rollRecord: newLineParams })
}

function wait(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }