import {
    DialogGenerateOptionsButtons,
    DialogGenerateOptionsSlider,
    DialogGenerateOptionsUploadFile,
    DialogLine,
    DialogLineRegenerate,
    DialogLinesEmpty,
    DialogLinesList,
    DialogNpcSelectCard,
    DialogNpcSelectCardForm,
    PopupNotification,
    SectionClosed,
    SectionDialogGenerate,
    SectionDialogNpcSelect,
} from 'devlink'
import { characterTypes } from 'entities/character'
import { characterStore } from 'entities/character/character.model'
import { dialogModel } from 'entities/dialog'
import {
    useCreateDiagenEventMutation,
    useCreateDialogLineMutation,
    useCreatePromptMutation,
    useCreateStateChangesMutation,
    useCreateTopicDetectionMutation,
    useDiagenFilesUploadMutation,
    useGetEventsMutation,
    useGetTagsMutation,
    useInitSessionMutation,
    useRegenerateDialogLineMutation,
    useUpdateHistoryMutation,
} from 'entities/dialog-line/dialog-line.queries'
import { DialogLine as DialogLineType } from 'entities/dialog-line/dialog-line.types'
import {
    DiagenEvents,
    EventOption,
    StateTags,
    TagOption
} from 'entities/session/session.model'
import {
    createLines,
    generateNextLine,
} from 'features/dialog-line/generate/generate-next-line'
import {
    regenerateOneLine,
} from 'features/dialog-line/generate/regenerate-line'
import _ from 'lodash'
import { DialogContext } from 'pages/dialog/dialog-page.model'
import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Loader } from 'shared/ui/loader'
import { SliderRange } from 'shared/ui/slider'

type SectionDialogNpcSelectContainerProps = {
    isLoading?: boolean
    characters: characterTypes.Npcs
    // characters: characterTypes.Characters
    temperature: number
    toxicity: number
    token: number
    language: string
    isGenerating: boolean
    tags: StateTags
    events: DiagenEvents
    instruction: string
    selectedCharacters: dialogModel.CharacterState[]
    setInstruction: (instruction: string) => void
    setToxicity: (toxicity: number) => void
    setTemperature: (temperature: number) => void
    setToken: (token: number) => void
    setLanguage: (language: string) => void
}
export function SectionDialogNpcSelectContainer({
    isLoading,
    characters,
    temperature,
    toxicity,
    token,
    language,
    isGenerating,
    tags,
    events,
    selectedCharacters,
    setToxicity,
    setTemperature,
    setToken,
    setLanguage,
}: SectionDialogNpcSelectContainerProps) {
    const { dialogStore } = useContext(DialogContext)
    const [fileNameTopics, setFileNameTopics] = useState<string>('')
    const [fileNameEvents, setFileNameEvents] = useState<string>('')
    const [fileNameTagsWeight, setFileNameTagsWeight] = useState<string>('')
    const [fileNameCharactersInfos, setFileNameCharactersInfos] =
        useState<string>('')

    const [fileTopics, setFileTopics] = useState<File>()
    const [fileEvents, setFileEvents] = useState<File>()
    const [fileCharactersInfos, setFileCharactersInfos] = useState<File>()
    const [fileTagsWeight, setFileTagsWeight] = useState<File>()

    const [getUploadFiles, setGetUploadFiles] = useState(false)
    const [tagsOptions, setTagsOptions] = useState<TagOption[]>([])
    const [eventsOptions, setEventsOptions] = useState<EventOption[]>([])

    const [otherCharacter, setOtherCharacter] = useState<string[]>(['', ''])
    const [currentCharacters, setCurrentCharacter] = useState<string[]>(['', ''])
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })

    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)

    const { setIsModalSwapOpen, isSwapTriggered, setIsSwapTriggered } =
        useContext(DialogContext)

    // load the dialog store when the component mounts
    useLayoutEffect(() => {
        dialogStore.getState().load()
    }, [])

    // save the dialog store when the component unmounts
    useLayoutEffect(() => {
        return () => {
            dialogStore.getState().save()
        }
    }, [])

    useLayoutEffect(() => {

        if (
            currentCharacters[0] !== selectedCharacters[0].character?.name ||
            currentCharacters[1] !== selectedCharacters[1].character?.name
        ) {
            if (selectedCharacters[0].character?.name === 'Player') {
                setOtherCharacter([
                    selectedCharacters[1].character?.name || '',
                    selectedCharacters[0].character?.name || '',
                ])
            } else {
                setOtherCharacter([
                    selectedCharacters[0].character?.name || '',
                    selectedCharacters[1].character?.name || '',
                ])
            }
    
            if (selectedCharacters[1].character?.name === 'Player') {
                setOtherCharacter([
                    selectedCharacters[0].character?.name || '',
                    selectedCharacters[1].character?.name || '',
                ])
            } else {
                setOtherCharacter([
                    selectedCharacters[1].character?.name || '',
                    selectedCharacters[0].character?.name || '',
                ])
            }
        }
        setCurrentCharacter([
            selectedCharacters[0].character?.name || '',
            selectedCharacters[1].character?.name || '',
        ])
    }, [selectedCharacters])

    const handleFileUploadTopics = (uploadedFileTopics: File) => {
        const fileExtension = uploadedFileTopics.name.split('.').pop()
        const allowedExtensions = ['csv', 'xls', 'xlsx']

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileTopics(uploadedFileTopics)
            setFileNameTopics(uploadedFileTopics.name)
        }
    }

    const handleFileUploadEvents = (uploadedFileEvents: File) => {
        const fileExtension = uploadedFileEvents.name.split('.').pop()
        const allowedExtensions = ['csv', 'xls', 'xlsx']

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileEvents(uploadedFileEvents)
            setFileNameEvents(uploadedFileEvents.name)
        }
    }

    const handleFileUploadCharactersInfos = (
        uploadedFileCharactersInfos: File
    ) => {
        const fileExtension = uploadedFileCharactersInfos.name.split('.').pop()
        const allowedExtensions = ['csv', 'xls', 'xlsx']

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileCharactersInfos(uploadedFileCharactersInfos)
            setFileNameCharactersInfos(uploadedFileCharactersInfos.name)
        }
    }

    const handleFileTagsWeight = (uploadedFileTagsWeight: File) => {
        const fileExtension = uploadedFileTagsWeight.name.split('.').pop()
        const allowedExtensions = ['csv', 'xls', 'xlsx']

        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFileTagsWeight(uploadedFileTagsWeight)
            setFileNameTagsWeight(uploadedFileTagsWeight.name)
        }
    }

    useEffect(() => {
        if (tags) {
            const stateTagsOptions = tags.map((tag: string) => ({
                label: tag,
                value: tag,
            }))
            setTagsOptions(stateTagsOptions)
        }
    }, [tags])

    useEffect(() => {
        if (events) {
            const eventsOptions = events.map((event: string) => ({
                label: event,
                value: event,
            }))
            setEventsOptions(eventsOptions)
        }
    }, [events])

    const languageOptions = [
        {
            t: 'English',
            v: 'en',
        },
        {
            t: 'French',
            v: 'fr',
        },
        {
            t: 'Spanish',
            v: 'es',
        },
        {
            t: 'German',
            v: 'de',
        },
        {
            t: 'Italian',
            v: 'it',
        },
        {
            t: 'Portuguese',
            v: 'pt',
        },
    ]
    //init session
    const { mutate: initSession, isPending: isInitSessionPending } =
        useInitSessionMutation()

    //upload files
    const {
        mutate: uploadDiagenFiles,
        isPending: isUploadDiagenFilesPending,
        isError: isUploadDiagenFilesError,
        isSuccess: isUploadDiagenFilesSuccess,
    } = useDiagenFilesUploadMutation()

    const { mutate: getTags } = useGetTagsMutation()

    const { mutate: getEvents } = useGetEventsMutation()

    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }

    useEffect(() => {
        if (isUploadDiagenFilesSuccess) {
            getTags()
            getEvents()
            openPopup(
                '\ue902',
                'Files uploaded',
                'The files have been uploaded successfully.'
            )
        }
        if (isUploadDiagenFilesError) {
            openPopup(
                '\ue91e',
                'Files not uploaded',
                'The files have not been uploaded. Please try again.'
            )
        }
    }, [isUploadDiagenFilesSuccess, isUploadDiagenFilesError])

    //Je veux init la session à la première selection de personnage

    useLayoutEffect(() => {
        if (characters.length > 0) {
            initSession()
        }
    }, [characters])

    // the slider ranges from 0 to 100. The token ranges from 10 to 300. I need to transform it to fit the slider
    const transformValue = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((range_max - range_min) * (value - min)) / (max - min) + range_min
        )
    }

    // revert the transformation
    const revertTransform = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((value - range_min) * (max - min)) / (range_max - range_min) + min
        )
    }

    if (isLoading) {
        return (
            <SectionDialogNpcSelect
                slotDialogNpcSelectCards={
                    <>
                        {Array.from({ length: 2 }).map((_, index) => (
                            <div
                                key={index}
                                style={{
                                    background: '#1E2338',
                                    height: '327px',
                                    borderRadius: '8px',
                                }}
                            >
                                <Loader size="parent" />
                            </div>
                        ))}
                    </>
                }
            />
        )
    }
    return (
        <>
            <SectionDialogNpcSelect
                slotDialogNpcSelectCards={
                    <>
                        {/* add a button that logs the dialogStore */}
                        {characters.length > 0 && (
                            <>
                                {dialogStore
                                    .getState()
                                    .characterStates.map((_, index) => (
                                        <DialogNpcSelectCardContainer
                                            key={index}
                                            index={index}
                                            swap={isSwapTriggered}
                                            characters={characters}
                                            selectedCharacter={
                                                selectedCharacters[index]
                                            }
                                            tagsOptions={tagsOptions}
                                            eventsOptions={eventsOptions}
                                            otherCharacter={otherCharacter}
                                            setOtherCharacter={
                                                setOtherCharacter
                                            }
                                            setSwap={setIsSwapTriggered}
                                            setSwapIsOpen={setIsModalSwapOpen}
                                            onSelectCharacter={(character) => {
                                                dialogStore
                                                    .getState()
                                                    .setCharacter(
                                                        index,
                                                        character
                                                    )
                                                dialogStore.setState({
                                                    dialogLines: [],
                                                })
                                            }}
                                        />
                                    ))}
                            </>
                        )}
                    </>
                }
                rpButtonSwap={{
                    onClick: (e: Event) => {
                        e.preventDefault()

                        if (!isGenerating) {
                            // swap the characters
                            const tempCharacterState =
                                dialogStore.getState().characterStates[0]

                            dialogStore
                                .getState()
                                .setCharacter(
                                    0,
                                    dialogStore.getState().characterStates[1]
                                        .character
                                )
                            dialogStore
                                .getState()
                                .setCharacter(1, tempCharacterState.character)

                            // Swap character backgrounds
                            dialogStore
                                .getState()
                                .setCharacterBackground(
                                    dialogStore.getState().characterStates[1]
                                        .background,
                                    0
                                )
                            dialogStore
                                .getState()
                                .setCharacterBackground(tempCharacterState.background, 1)
                            //swap tags
                            dialogStore
                                .getState()
                                .setSelectedTags(
                                    dialogStore.getState().characterStates[1]
                                        .selectedTags,
                                    0
                                )
                            dialogStore
                                .getState()
                                .setSelectedTags(
                                    tempCharacterState.selectedTags,
                                    1
                                )
                            //Modal open
                            setIsModalSwapOpen?.(true)
                        }
                    },
                    className: `button is-secondary ${isGenerating && 'is-disabled'}`,
                }}
            />
            <DialogGenerateOptionsSlider
                slotTemperatureRange={
                    <SliderRange
                        value={transformValue(temperature, 0, 2, 0, 100)}
                        onChange={(value: number) => {
                            setTemperature(
                                Math.round(revertTransform(value, 0, 2, 0, 100))
                            )
                        }}
                        min={0}
                        max={200}
                    />
                }
                slotTokenRange={
                    <SliderRange
                        value={Math.round(
                            transformValue(token, 10, 200, 0, 100)
                        )}
                        onChange={(value: number) => {
                            setToken(
                                Math.round(
                                    revertTransform(value, 10, 200, 0, 100)
                                )
                            )
                        }}
                    />
                }
                slotToxicityRange={
                    <SliderRange
                        value={toxicity * 100}
                        onChange={(value: number) => {
                            setToxicity(value / 100)
                        }}
                    />
                }
                rpLanguage={{
                    label: 'Language',
                    options: languageOptions.sort((a, b) =>
                        a.t.localeCompare(b.t)
                    ),
                    value: language,
                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                        const selectedLanguage = e.target.value
                        setLanguage(selectedLanguage)
                    },
                }}
            />
            {getUploadFiles ? (
                <DialogGenerateOptionsUploadFile
                    fileNameEvents={fileNameEvents}
                    fileNameCharactersInfos={fileNameCharactersInfos}
                    fileNameTopics={fileNameTopics}
                    fileNameTagsWeight={fileNameTagsWeight}
                    rpInitSession={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault()
                            initSession()
                        },
                        className: `button is-secondary ${isInitSessionPending && 'is-disabled '}`,
                    }}
                    rpUploadTopics={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault()
                            const input = document.createElement('input')
                            input.type = 'file'
                            input.accept = '.csv, .xls, .xlsx'
                            input.onchange = (e) => {
                                const target = e.target as HTMLInputElement
                                if (target.files) {
                                    handleFileUploadTopics(target.files[0])
                                }
                            }
                            input.click()
                        },
                        onDragOver: (e: React.DragEvent) => {
                            e.preventDefault()
                        },
                        onDrop: (e: React.DragEvent) => {
                            e.preventDefault()
                            const droppedFileTopics = e.dataTransfer.files[0]
                            handleFileUploadTopics(droppedFileTopics)
                        },
                    }}
                    rpUploadEvents={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault()
                            const input = document.createElement('input')
                            input.type = 'file'
                            input.accept = '.csv, .xls, .xlsx'
                            input.onchange = (e) => {
                                const target = e.target as HTMLInputElement
                                if (target.files) {
                                    handleFileUploadEvents(target.files[0])
                                }
                            }
                            input.click()
                        },
                        onDragOver: (e: React.DragEvent) => {
                            e.preventDefault()
                        },
                        onDrop: (e: React.DragEvent) => {
                            e.preventDefault()
                            const droppedFileEvents = e.dataTransfer.files[0]
                            handleFileUploadEvents(droppedFileEvents)
                        },
                    }}
                    rpUploadCharactersInformations={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault()
                            const input = document.createElement('input')
                            input.type = 'file'
                            input.accept = '.csv, .xls, .xlsx'
                            input.onchange = (e) => {
                                const target = e.target as HTMLInputElement
                                if (target.files) {
                                    handleFileUploadCharactersInfos(
                                        target.files[0]
                                    )
                                }
                            }
                            input.click()
                        },
                        onDragOver: (e: React.DragEvent) => {
                            e.preventDefault()
                        },
                        onDrop: (e: React.DragEvent) => {
                            e.preventDefault()
                            const droppedFileCharactersInfos =
                                e.dataTransfer.files[0]
                            handleFileUploadCharactersInfos(
                                droppedFileCharactersInfos
                            )
                        },
                    }}
                    rpUploadTagsWeight={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault()
                            const input = document.createElement('input')
                            input.type = 'file'
                            input.accept = '.csv, .xls, .xlsx'
                            input.onchange = (e) => {
                                const target = e.target as HTMLInputElement
                                if (target.files) {
                                    handleFileTagsWeight(target.files[0])
                                }
                            }
                            input.click()
                        },
                        onDragOver: (e: React.DragEvent) => {
                            e.preventDefault()
                        },
                        onDrop: (e: React.DragEvent) => {
                            e.preventDefault()
                            const droppedFileTagsWeight =
                                e.dataTransfer.files[0]
                            handleFileTagsWeight(droppedFileTagsWeight)
                        },
                    }}
                    rpButtonSendFiles={{
                        className: `button is-primary`,
                        onClick: (e: React.MouseEvent) => {
                            if (!fileTopics) {
                                openPopup(
                                    '\ue91e',
                                    'Files not uploaded',
                                    'Please upload the topics file.'
                                )
                                return
                            }
                            if (!fileEvents) {
                                openPopup(
                                    '\ue91e',
                                    'Files not uploaded',
                                    'Please upload the events file.'
                                )
                                return
                            }
                            if (!fileCharactersInfos) {
                                openPopup(
                                    '\ue91e',
                                    'Files not uploaded',
                                    'Please upload the characters information file.'
                                )
                                return
                            }
                            if (!fileTagsWeight) {
                                openPopup(
                                    '\ue91e',
                                    'Files not uploaded',
                                    'Please upload the tags weight file.'
                                )
                                return
                            }
                            uploadDiagenFiles({
                                diagenuploadPDF: {
                                    topics: fileTopics,
                                    diagenEvents: fileEvents,
                                    characterInformation: fileCharactersInfos,
                                    tagsWeight: fileTagsWeight,
                                },
                            })
                        },
                    }}
                    rpButtonTemplatesFiles={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault()
                            const files = [
                                { href: '/diagenevents/characterInformation.csv', download: 'characterInformation-template.csv' },
                                { href: '/diagenevents/diagenEvents.csv', download: 'diagenEvents-template.csv' },
                                { href: '/diagenevents/topics.csv', download: 'topics-template.csv' },
                                { href: '/diagenevents/tagsWeight.csv', download: 'tagsWeight-template.csv' },
                            ]
                            files.forEach(file => {
                                const link = document.createElement('a')
                                link.href = file.href
                                link.download = file.download
                                link.click()
                            })
                        },
                    }}
                    onClose={{
                        onClick: () => {
                            setGetUploadFiles(false)
                        },
                    }}
                />
            ) : (
                <SectionClosed
                    rpButtonOpen={{
                        onClick: (e: Event) => {
                            e.preventDefault()
                            setGetUploadFiles(true)
                        },
                    }}
                    rpIcon={'\ue902'}
                    title={'Upload Diagen Files'}
                    subtitle={
                        'Upload files to improve the quality of the generated dialog'
                    }
                />
            )}
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

type DialogNpcSelectCardContainerProps = {
    index: number
    swap: boolean | undefined
    characters: characterTypes.Npcs
    selectedCharacter: dialogModel.CharacterState
    tagsOptions: TagOption[]
    eventsOptions: EventOption[]
    otherCharacter: string[]
    setSwap: ((value: boolean) => void) | undefined
    setSwapIsOpen: ((value: boolean) => void) | undefined
    setOtherCharacter: (value: string[]) => void
    onSelectCharacter?: (character?: characterTypes.Npc) => void
}
function DialogNpcSelectCardContainer({
    index,
    swap,
    characters, // from character store //TODO: remove this -> selectedCharacters from dialog store will be enough
    selectedCharacter, // from dialog store
    tagsOptions,
    eventsOptions,
    otherCharacter,
    setSwap,
    onSelectCharacter,
    setSwapIsOpen,
    setOtherCharacter,
}: DialogNpcSelectCardContainerProps) {
    const { playerStore, dialogStore } = useContext(DialogContext)
    const [characterImage, setCharacterImage] = useState<string | null>(null)
    const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
        null
    )

    const [npcs, setNpcs] = useState<any[] | null | undefined>(null)
    const [selectedCharacterInfo, setSelectedCharacterInfo] =
        useState<characterTypes.Npc | null>(null)

    const [selectedTagsOptions, setSelectedTagsOptions] = useState<TagOption[]>(
        []
    )
    const [customName, setCustomName] = useState<string | null>(null)
    const npc_name = selectedCharacterInfo?.name || ''
    const other_name = otherCharacter || ''

    const [core_description, setBackground] = useState(
        dialogStore.getState().characterStates[index].background ||
        selectedCharacterInfo?.background ||
            ''
    )
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })

    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)

    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }
    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (
                state.currentTeam?.name !== selectedTeam &&
                npcs !== state.npcs
            ) {
                setCharacterImage(null)
                setSelectedTeam(state.currentTeam?.name)
                setNpcs(state.npcs)
                setSelectedCharacterInfo(null)
                setCharacterImage(null)
                setBackground('')
                dialogStore.getState().reset()
            }
        })
    }, [selectedTeam, npcs])

    useEffect(() => {
        if (selectedCharacterInfo) {
            const currentState = characterStore.getState().images
            const characterImage = currentState?.find(
                (image) => image.name === selectedCharacterInfo.name
            )
            setCharacterImage(characterImage?.image || null)
        }
    }, [selectedCharacterInfo])

    useLayoutEffect(() => {
        if (selectedCharacter && !_.isEqual(
            selectedCharacter.selectedTags.sort(),
            selectedTagsOptions.map((option) => option.value).sort()
          )) {
            // update selected tags
            const selectedTags = selectedCharacter.selectedTags;
            setSelectedTagsOptions(
                tagsOptions.filter((option) =>
                    selectedTags.includes(option.value)
                )
            );
            if (selectedTagsOptions) {
                callStateChanges({
                    stateChanges: {
                        stateTags: selectedCharacter.selectedTags.sort(),
                        npc_name: selectedCharacterInfo?.name ?? '',
                    },
                })
            }
        }
    }, [selectedCharacter?.selectedTags]);



    const characterOptions = [
        ...characters?.map((character) => ({
            t: character?.name,
            v: character?.id.toString(),
        })),
    ]

    useEffect(() => {
        const defaultCharacter = characters.find(
            (character) => character.name === 'Player'
        )
        if (defaultCharacter) {
            setSelectedCharacterInfo(defaultCharacter)
            setBackground(defaultCharacter.background || '')
            onSelectCharacter?.(defaultCharacter)
            if (defaultCharacter.name === 'Player') {
                playerStore?.getState().setName('Player')
                playerStore
                    ?.getState()
                    .setGender(Math.random() < 0.5 ? 'male' : 'female')
            }
        }
    }, [characters])

    //callStateChanges
    const { 
        mutate: callStateChanges,
        isSuccess: isStateChangesSuccess,
        isError: isStateChangesError,
        data: datasStateChanges,
    } = useCreateStateChangesMutation()

    //Create Prompt
    const {
        mutate: handleCreatePrompt,
        isSuccess: isCreatePromptSuccess,
        data: datasPrompt,
    } = useCreatePromptMutation()

    useEffect(() => {
        if (isCreatePromptSuccess) {
            const newPrompt =
                datasPrompt.prompt ||
                `Your name is ${npc_name} and you are talking to ${other_name}. You have to talk in the first perspective with dialogue.`

            setBackground(newPrompt)
            dialogStore.getState().setCharacterBackground(newPrompt, index)
        }
    }, [isCreatePromptSuccess])

    useEffect(() => {
        if (selectedCharacterInfo?.name === 'Player') {
            setBackground(``)
            dialogStore.getState().setCharacterBackground(``, index)
        } else {
            setBackground(selectedCharacterInfo?.background || '')
            dialogStore
                .getState()
                .setCharacterBackground(
                    selectedCharacterInfo?.background || '',
                    index
                )
        }
    }, [selectedCharacterInfo, other_name, index, dialogStore])

    useEffect(() => {
        if (isStateChangesSuccess) {
            handleCreatePrompt({
                promptData: {
                    npc_name: selectedCharacterInfo?.name ?? '',
                    player_name:
                        otherCharacter[index] ?? '',
                    local_exec: true,
                },
            })
        }
        else if (isStateChangesError) {
            openPopup(
                '\ue91e',
                'Error',
                'An error occurred. Please try again.'
            )
        }
    }, [isStateChangesSuccess, isStateChangesError])
    // }, [isStateChangesSuccess, isStateChangesError, selectedCharacter.selectedTags])

    return (
        <>
            <DialogNpcSelectCard
                dialogNpcSelectCardImg={
                    selectedCharacterInfo && selectedCharacterInfo.name === 'Player'
                        ? playerStore.getState().gender === 'female'
                            ? '/images/you_female.png'
                            : '/images/you_male.png'
                        : characterImage || '/images/blank.png'
                }
                slotNpcCardForm={
                    <DialogNpcSelectCardForm
                        rpDialogNpcSelectCardDropdown={{
                            label: 'Choose your NPC',
                            value: selectedCharacterInfo?.id.toString(),
                            name: selectedCharacterInfo?.name.toString(),
                            options: characterOptions,
                            onChange: (event: any) => {
                                const character =
                                    characters.find(
                                        (character) =>
                                            character.id.toString() ===
                                            event.target.value
                                    ) || null
                                setSelectedCharacterInfo(character)
                                setBackground(character?.background || '')
                                onSelectCharacter?.(character || undefined)
                                if (character?.name === 'Player') {
                                    playerStore?.getState().setName('Player')
                                    playerStore
                                        ?.getState()
                                        .setGender(
                                            Math.random() < 0.5
                                                ? 'male'
                                                : 'female'
                                        )
                                }
                            },
                        }}
                        isDescriptionEmpty={core_description === ''}
                        isPlayerSelected={selectedCharacterInfo?.name === 'Player'}
                        isTagsEmpty={tagsOptions.length === 0}
                        rpPlayerCustomName={{
                            className:
                                'input background-blue-150 min-h-3rem cursor-pointer',
                            value: customName,
                            onChange: (event: any) => {
                                setCustomName(event.target.value)
                                if (index === 0) {
                                    setOtherCharacter([
                                        otherCharacter[0],
                                        event.target.value,
                                    ])
                                } else {
                                    setOtherCharacter([
                                        event.target.value,
                                        otherCharacter[1],
                                    ])
                                }
                            },
                        }}
                        rpDialogNpcSelectCardCoreDescription={{
                            className:
                                'input background-blue-150 min-h-10rem overflow-scroll',
                            value: core_description,
                            disabled: selectedCharacterInfo?.name === 'Player',
                            onChange: (event: any) => {
                                setBackground(event.target.value)
                                dialogStore
                                    .getState()
                                    .setCharacterBackground(
                                        event.target.value,
                                        index
                                    )
                            },
                        }}
                        rpTagsDropdown={{
                            placeholder: 'Choose your tags...',
                            value: selectedTagsOptions,
                            options: tagsOptions.sort((a, b) =>
                                a.label.localeCompare(b.label)
                            ),
                            onChange: (selectedOptions: TagOption[]) => {
                                // update dialog store
                                dialogStore.getState().setSelectedTags(
                                    selectedOptions.map((option) => option.value),
                                    index
                                )
                                // setSelectedTagsOptions(selectedOptions)
                            },
                        }}
                    />
                }
            />
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

type SectionDialogGenerateContainerProps = {
    characters: characterTypes.Npcs
    tagsOptions: TagOption[]
    temperature: number
    toxicity: number
    events: DiagenEvents
    token: number
    language: string
    selectedCharacters: dialogModel.CharacterState[]
    setIsGenerating: (isGenerating: boolean) => void
}

export function SectionDialogGenerateContainer({
    characters,
    temperature,
    tagsOptions,
    toxicity,
    events,
    token,
    language,
    selectedCharacters,
    setIsGenerating,
}: SectionDialogGenerateContainerProps) {
    const {
        setIsModalRegenerateOpen,
        isRegenerateTriggered,
        setIsRegenerateTriggered,
    } = useContext(DialogContext)

    const { dialogStore } = useContext(DialogContext)

    const [isComplete, setIsComplete] = useState(false)
    const [lines, setLines] = useState<DialogLineType[]>(
        dialogStore.getState().dialogLines || []
    )

    // line generation
    const [linesToGenerate, setLinesToGenerate] = useState(0)
    const [shouldScroll, setShouldScroll] = useState(true)

    // end regeneration
    const [startEndIndex, setStartEndIndex] = useState(-1)

    const [instruction, setInstruction] = useState('')

    const [eventsOptions, setEventsOptions] = useState<EventOption[]>([])
    const [selectedEvents, setSelectedEvents] = useState<EventOption | null>(
        null
    )
    const isEventsEmpty = eventsOptions.length === 0
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })

    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)

    //let isComplete si character et description sont remplis

    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }

    useEffect(() => {
        if (events) {
            const eventsOptions = events.map((event: string) => ({
                label: event,
                value: event,
            }))
            setEventsOptions(eventsOptions)
        }
    }, [events])

    const {
        mutate: callDiagenEvent,
        isPending: isDiagenEventPending,
        isSuccess: isDiagenEventSuccess,
        isError: isDiagenEventError,
        data: diagenEventData,
    } = useCreateDiagenEventMutation()

    // line generation
    const {
        mutate: createDialogLine,
        isPending: isGeneratingLine,
        isSuccess: isSuccessCreateLine,
    } = useCreateDialogLineMutation()

    // line regeneration
    const {
        mutate: regenerateDialogLine,
        isPending: isRegeneratingEnd,
        isSuccess: isRegeneratedEnd,
        data: regeneratedLine,
    } = useRegenerateDialogLineMutation()

    const {
        mutate: getTags,
        isPending: isGetTagsPending,
        isSuccess: isGetTagsSuccess,
        isError: isGetTagsError,
    } = useGetTagsMutation()

    //Update History
    const {
        mutate: handleUpdateHistory,
    } = useUpdateHistoryMutation()

    // subscribe to state changes
    useLayoutEffect(() => {
        const unsubscribeDialogStore = dialogStore.subscribe((state) => {
            computeIsComplete(state)
            setLines(state.dialogLines)
        })
        computeIsComplete(dialogStore.getState())

        return () => {
            unsubscribeDialogStore()
        }
    }, [])



    useLayoutEffect(() => {
        if (isSuccessCreateLine && lines.length > 0) {
            handleUpdateHistory({
                updateHistories: {
                    npc_name: lines[lines.length - 1].npc_name,
                    question: lines[lines.length - 1].question,
                    response: lines[lines.length - 1].response,
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCreateLine])

    const handleButtonClick = () => {
        if (selectedEvents) {
            callDiagenEvent({
                diagenEvents: {
                    diagenEvent: selectedEvents?.value || '',
                    npc_name:
                        dialogStore.getState().characterStates[0].character
                            ?.name ?? '',
                },
            })
        }
    }

    useLayoutEffect(() => {
        if (isDiagenEventSuccess) {
            let characterIndex = 0
            if (dialogStore.getState().dialogLines.length === 0 || dialogStore.getState().dialogLines.length % 2 === 1) {
                characterIndex = 0
            } else {
                characterIndex = 1
            }

            if (diagenEventData?.Instruction) {
                setInstruction((diagenEventData as any).Instruction || '')
                generateLines(1, dialogStore.getState().startEndIndex)
            } else if (diagenEventData?.SayVerbatim) {
                createLines(
                    dialogStore.getState().startEndIndex,
                    diagenEventData.SayVerbatim,
                    dialogStore
                )
            }
            if (diagenEventData?.StateTags) {
                const currentTags = dialogStore.getState().characterStates[characterIndex].selectedTags 
                
                // update tags with new tags appending
                dialogStore.getState().setSelectedTags(
                    Array.from(new Set([...currentTags, ...diagenEventData?.StateTags])),
                    characterIndex 
                )
            }
            }
        if (isDiagenEventError) {
            openPopup(
                '\ue91e',
                'Event not selected',
                'The event has not been selected. Please try again.'
            )
        }
    }, [isDiagenEventSuccess, isDiagenEventError])

    // listen to line generation success
    useLayoutEffect(() => {
        if (isSuccessCreateLine) {
            setLinesToGenerate(linesToGenerate - 1)
            if (shouldScroll) {
                window.scrollTo(0, document.body.scrollHeight)
            }

            if (
                selectedCharacters.some(
                    (character) => character.character?.name === 'Player'
                )
            ) {
                dialogStore?.setState({
                    dialogLines: [
                        ...lines,
                        {
                            npc_name: 'Player',
                            core_description: '',
                            player_name: '',
                            instruction: '',
                            question: lines[lines.length - 1].response,
                            response: '',
                            llama_generation_params: {
                                max_tokens: 0,
                                temperature: 0,
                                stream: true,
                                top_p: 0,
                                top_k: 0,
                                // stop: ["\n"],
                                frequency_penalty: 0,
                                presence_penalty: 0,
                            },
                            toxicity: 0,
                            language: language,
                        },
                    ],
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCreateLine])

    // listen to line regeneration success
    useLayoutEffect(() => {
        if (regeneratedLine) {
            const newLines = [...lines]
            newLines[startEndIndex] = regeneratedLine
            dialogStore.setState({
                dialogLines: newLines,
            })
            setStartEndIndex((index) => index + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regeneratedLine])

    // generate next line if needed
    useLayoutEffect(() => {
        if (linesToGenerate > 0) {
            generateNextLine(createDialogLine, dialogStore, {
                llama_generation_params: {
                    max_tokens: token,
                    temperature: temperature,
                    stream: true,
                    top_p: 0.95,
                    top_k: 0,
                    // stop: ['\n'],
                    frequency_penalty: 0.8,
                    presence_penalty: 0.3,
                },
                toxicity: toxicity,
                language: language,
                instruction: instruction,
            })
            setInstruction('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linesToGenerate])

    // compute is the form is complete
    function computeIsComplete(state: dialogModel.State) {
        if (
            selectedCharacters.every((characterState) => {
                return characterState.character !== undefined
            })
        ) {
            setIsComplete(true)
        } else {
            setIsComplete(false)
        }
    }

    // ask to regenerate all the dialog
    useLayoutEffect(() => {
        if (isRegenerateTriggered) {
            dialogStore.setState({
                dialogLines: [],
            })
            if (selectedCharacters[0].character?.name === 'Player') {
                dialogStore?.setState({
                    dialogLines: [
                        {
                            npc_name: 'Player',
                            core_description: '',
                            player_name: '',
                            instruction: '',
                            question:
                                lines.length > 0
                                    ? lines[lines.length - 1].response
                                    : '',
                            response: '',
                            llama_generation_params: {
                                max_tokens: 0,
                                temperature: 1,
                                stream: true,
                                top_p: 0.95,
                                top_k: 0,
                                // stop: ['\n'],
                                frequency_penalty: 0.8,
                                presence_penalty: 0.3,
                            },
                            toxicity: 0,
                            language: language,
                        },
                    ],
                })
                setIsRegenerateTriggered?.(false)
            } else if (selectedCharacters[1].character?.name === 'Player') {
                generateLines(1, dialogStore.getState().startEndIndex)
                setIsRegenerateTriggered?.(false)
            } else {
                generateLines(
                    lines.length,
                    dialogStore.getState().startEndIndex
                )
                setIsRegenerateTriggered?.(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRegenerateTriggered])

    // ask to generate some lines
    function generateLines(numLines: number, startEndIndex?: number) {
        setShouldScroll(true)
        if (selectedCharacters[lines.length % 2].character?.name === 'Player') {
            dialogStore?.setState({
                dialogLines: [
                    ...lines,
                    {
                        npc_name: 'Player',
                        core_description: '',
                        player_name: '',
                        instruction: '',
                        question:
                            lines.length > 0
                                ? lines[lines.length - 1].response
                                : '',
                        response: '',
                        llama_generation_params: {
                            max_tokens: 0,
                            temperature: 0,
                            stream: true,
                            top_p: 0.95,
                            top_k: 0,
                            // stop: ['\n'],
                            frequency_penalty: 0.8,
                            presence_penalty: 0.3,
                        },
                        toxicity: 0,
                        language: language,
                    },
                ],
            })
        } else {
            setLinesToGenerate(numLines)
            dialogStore.setState({
                startEndIndex:
                    startEndIndex !== undefined ? startEndIndex : numLines / 2,
            })
        }
    }

    // ask to regenerate the end of the conversation
    function regenerateEnd(index: number) {
        setStartEndIndex(index)
    }

    useLayoutEffect(() => {
        if (!isGeneratingLine && linesToGenerate <= 1) {
            setIsGenerating(false)
        } else {
            setIsGenerating(true)
        }
    }, [isGeneratingLine])

    return (
        <>
            <SectionDialogGenerate
                slotDialogGenerate={
                    <>
                        {lines.length === 0 && (
                            <>
                                <DialogGenerateOptionsButtonsContainer
                                    isEnabled={!isGeneratingLine}
                                    selectedCharacters={selectedCharacters}
                                    eventsEmpty={isEventsEmpty}
                                    eventsOptions={eventsOptions}
                                    selectedEvents={selectedEvents}
                                    setSelectedEvents={setSelectedEvents}
                                    handleButtonClick={handleButtonClick}
                                    onClick={generateLines}
                                />
                                {isGeneratingLine ? (
                                    <div
                                        style={{
                                            background: '#1E2338',
                                            height: '150px',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        <Loader size="parent" />
                                    </div>
                                ) : (
                                    <DialogLinesEmpty />
                                )}
                            </>
                        )}
                        {lines.length !== 0 && (
                            <DialogLinesList
                                slotDialogueLines={
                                    <>
                                        {lines
                                            .filter(
                                                (_, index) =>
                                                    startEndIndex < 0 ||
                                                    startEndIndex > index
                                            )
                                            .map((_, index) => (
                                                <DialogLineContainer
                                                    key={index}
                                                    index={index}
                                                    tagsOptions={tagsOptions}
                                                    eventsOptions={
                                                        eventsOptions
                                                    }
                                                    isPending={isGeneratingLine}
                                                    generateEnd={regenerateEnd}
                                                    language=""
                                                    instruction={
                                                        lines[index].instruction
                                                    }
                                                    toxicity={
                                                        lines[index].toxicity
                                                    }
                                                    setInstruction={(
                                                        instruction: string
                                                    ) => {
                                                        const newLines = [
                                                            ...lines,
                                                        ]
                                                        newLines[
                                                            index
                                                        ].instruction =
                                                            instruction
                                                        dialogStore.setState({
                                                            dialogLines:
                                                                newLines,
                                                        })
                                                        return instruction
                                                    }}
                                                    setToxicity={(
                                                        toxicity: number
                                                    ) => {
                                                        const newLines = [
                                                            ...lines,
                                                        ]
                                                        newLines[
                                                            index
                                                        ].toxicity = toxicity
                                                        dialogStore.setState({
                                                            dialogLines:
                                                                newLines,
                                                        })
                                                        return toxicity
                                                    }}
                                                    temperature={
                                                        lines[index]
                                                            .llama_generation_params
                                                            .temperature
                                                    }
                                                    setTemperature={(
                                                        temperature: number
                                                    ) => {
                                                        const newLines = [
                                                            ...lines,
                                                        ]
                                                        newLines[
                                                            index
                                                        ].llama_generation_params.temperature =
                                                            temperature
                                                        dialogStore.setState({
                                                            dialogLines:
                                                                newLines,
                                                        })
                                                        return temperature
                                                    }}
                                                />
                                            ))}
                                        {(isGeneratingLine ||
                                            isRegeneratingEnd) && (
                                            <div
                                                style={{
                                                    background: '#1E2338',
                                                    height: '150px',
                                                    borderRadius: '8px',
                                                }}
                                            >
                                                <Loader size="parent" />
                                            </div>
                                        )}
                                    </>
                                }
                                rpButtonGenerateResponse={{
                                    onClick: (e: Event) => {
                                        e.preventDefault()
                                        !isGeneratingLine &&
                                            generateLines(
                                                1,
                                                dialogStore.getState()
                                                    .startEndIndex
                                            )
                                    },
                                    className: `button ${isGeneratingLine && 'is-disabled'}`,
                                }}
                                isPlayer={
                                    selectedCharacters[0].character?.name ===
                                    'Player' || selectedCharacters[1].character?.name === 'Player'
                                }
                                eventsEmpty={isEventsEmpty}
                                rpButtonChooseEvents={{
                                    className: `button is-secondary ${isGeneratingLine && 'is-disabled'}`,
                                    onClick: (e: React.MouseEvent) => {
                                        e.preventDefault()
                                        handleButtonClick()
                                        !isGeneratingLine &&
                                            generateLines(
                                                1,
                                                dialogStore.getState()
                                                    .startEndIndex
                                            )
                                    },
                                }}
                                rpEventsDropdown={{
                                    placeholder: 'Choose your major event...',
                                    disabled:
                                        dialogStore.getState()
                                            .characterStates[0].character
                                            ?.name === 'Player',
                                    value: selectedEvents,
                                    options: eventsOptions,
                                    onChange: (
                                        selectedOptions: EventOption
                                    ) => {
                                        setSelectedEvents(selectedOptions)
                                    },
                                }}
                                rpButtonRegenerateAll={{
                                    onClick: (e: Event) => {
                                        e.preventDefault()
                                        !isGeneratingLine &&
                                            setIsModalRegenerateOpen?.(true)
                                    },
                                    className: `button is-secondary ${isGeneratingLine && 'is-disabled'}`,
                                }}
                            />
                        )}
                    </>
                }
            />
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

type DialogLineContainerProps = {
    index: number
    isPending: boolean
    tagsOptions: TagOption[]
    eventsOptions: EventOption[]
    generateEnd: (index: number) => void
    language: string
    temperature: any
    instruction: string
    toxicity: any
    setInstruction: (instruction: string) => void
    setToxicity: (toxicity: number) => void
    setTemperature: (temperature: number) => number
}
function DialogLineContainer({
    index,
    isPending,
    tagsOptions,
    eventsOptions,
    generateEnd,
}: DialogLineContainerProps) {
    const { dialogStore, playerStore } = useContext(DialogContext)
    const lines = dialogStore.getState().dialogLines
    const [topicResult, setTopicResult] = useState<string[]>([])
    const line = lines[index]
    // the slider ranges from 0 to 100. The token ranges from 10 to 300. I need to transform it to fit the slider
    const transformValue = (
        value: number, // 50
        min: number, // 10
        max: number, // 300
        range_min: number, // 0
        range_max: number // 100
    ): number => {
        return (
            ((range_max - range_min) * (value - min)) / (max - min) + range_min
        )
    }

    // revert the transformation
    const revertTransform = (
        value: number,
        min: number,
        max: number,
        range_min: number,
        range_max: number
    ): number => {
        return (
            ((value - range_min) * (max - min)) / (range_max - range_min) + min
        )
    }
    const [player, setPlayer] = useState(playerStore?.getState())

    // start end radio button condition
    const [isStartEndSelected, setIsStartEndSelected] = useState(
        dialogStore.getState().startEndIndex === index
    )

    // listen to start end radio change
    useLayoutEffect(() => {
        dialogStore.subscribe((state) => {
            setIsStartEndSelected(state.startEndIndex === index)
        })
    }, [])

    // listen to start end radio change
    useLayoutEffect(() => {
        playerStore?.subscribe((state) => {
            setPlayer(state)
        })
    }, [])

    // line regeneration
    const [regeneratingLineIndex, setRegeneratingLineIndex] = useState(-1)

    // line regeneration
    const {
        mutate: regenerateDialogLine,
        isPending: isRegenerating,
        isSuccess: isRegenerated,
        data: regeneratedLine,
    } = useRegenerateDialogLineMutation()

    const {
        mutate: handleUpdateHistory,
        isPending: isUpdateHistoryPending,
        isSuccess: isUpdateHistorySuccess,
        isError: isUpdateHistoryError,
    } = useUpdateHistoryMutation()

    // listen to line regeneration success
    useLayoutEffect(() => {
        if (regeneratedLine) {
            const newLines = [...lines]
            newLines[regeneratingLineIndex] = regeneratedLine
            dialogStore.setState({
                dialogLines: newLines,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regeneratedLine])

    // ask to regenerate a line
    function regenerateLine(
        lineIndex: number,
        llama_generation_params: {
            max_tokens: number
            temperature: number
            stream: boolean
            top_p: number
            top_k: number
            // stop: string[]
            frequency_penalty: number
            presence_penalty: number
        },
        toxicity: number,
        instruction: string,
        language: string
    ) {
        setRegeneratingLineIndex(lineIndex)
        regenerateOneLine(regenerateDialogLine, dialogStore, {
            lineIndex,
            llama_generation_params: {
                max_tokens: llama_generation_params.max_tokens,
                temperature: llama_generation_params.temperature,
                stream: llama_generation_params.stream,
                top_p: llama_generation_params.top_p,
                top_k: llama_generation_params.top_k,
                // stop: llama_generation_params.stop,
                frequency_penalty: llama_generation_params.frequency_penalty,
                presence_penalty: llama_generation_params.presence_penalty,
            },
            toxicity: toxicity,
            language: language,
            instruction: instruction,
        })
    }

    const {
        mutate: callTopicDetection,
        isPending: isTopicDetectionPending,
        isSuccess: isTopicDetectionSuccess,
        isError: isTopicDetectionError,
        data: topicDetectionData,
    } = useCreateTopicDetectionMutation()

    useLayoutEffect(() => {
        if (isRegenerated && isRegenerated === true && line) {
            if (isRegenerated) {
                handleUpdateHistory({
                    updateHistories: {
                        npc_name: line.npc_name,
                        question: line.question,
                        response: line.response,
                    },
                })
            }
        }
    }, [isRegenerated])

    useLayoutEffect(() => {
        if (isTopicDetectionPending) {
            setTopicResult(['In progress...'])
        }
        if (isTopicDetectionSuccess) {
            setTopicResult(topicDetectionData?.detected_topic || [])
        }
    }, [isTopicDetectionSuccess, isTopicDetectionPending])

    return (
        <>
            {line && (
                <>
                    <DialogLineRegenerate
                        dialogLineRegenerateNumber={index + 1}
                        rpRegenerateButton={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                generateEnd(index)
                            },
                            className: `button is-secondary ${isPending && 'is-disabled'}`,
                        }}
                    />
                    {isRegenerating &&
                    regeneratingLineIndex === index &&
                    line.response.length === 0 ? (
                        <div
                            style={{
                                background: '#1E2338',
                                height: '150px',
                                borderRadius: '8px',
                            }}
                        >
                            <Loader size="parent" />
                        </div>
                    ) : (
                        
                        <DialogLine
                            rpTopicDetection={{
                                className: `button is-icon ${isTopicDetectionPending && 'is-disabled'}`,
                                onClick: (e: Event) => {
                                    e.preventDefault()
                                    callTopicDetection({
                                        topicDetection: {
                                            question: line.response.replace(
                                                /^"\s*/,
                                                ''
                                            ),
                                            npc_name: line.npc_name,
                                            model_path:
                                                './models/base/Meta-Llama-3.1-8B-Instruct-Q4_K_M.gguf',
                                            llama_generation_params: {
                                                temperature:
                                                    line.llama_generation_params
                                                        .temperature,
                                                max_tokens:
                                                    line.llama_generation_params
                                                        .max_tokens,
                                                top_p: line.llama_generation_params
                                                    .top_p,
                                            },
                                            llama_load_params: {
                                                n_gpu_layers: 1,
                                                n_ctx: 512,
                                                main_gpu: 1,
                                            },
                                            stateTags: dialogStore.getState().characterStates[index % 2].selectedTags,
                                        },
                                    })
                                },
                            }}
                            topicResult={topicResult}
                            tagsEmpty={tagsOptions.length === 0}
                            eventsEmpty={eventsOptions.length === 0}
                            dialogLineImage={
                                dialogStore.getState().characterStates[index % 2]
                                    .character?.name === 'Player'
                                    ? playerStore.getState().gender === 'female'
                                        ? '/images/you_female.png'
                                        : '/images/you_male.png'
                                    : dialogStore.getState().characterStates[index % 2]
                                        .character?.image || '/images/blank.png'
                            }
                            rpDialogLine={{
                                className: `dialogue-output-wrapper  ${index % 2 !== 0 && 'reverse-row'}`,
                            }}
                            isPlayer={
                                dialogStore.getState().characterStates[index % 2]
                                    .character?.name === 'Player'
                            }
                            slotDialogLinePlayerName={
                                dialogStore.getState().characterStates[index % 2]
                                    .character?.name === 'Player'
                                    ? ''
                                    : null
                            }
                            rpDialogLineInput={{
                                value: line?.response?.replace(/^"\s*/, '') || '',
                                onChange: (e: Event) => {
                                    e.preventDefault()
                                    const line =
                                        dialogStore.getState().dialogLines[index]
                                    line.response = (e.target as any).value.replace(
                                        /^"/,
                                        ''
                                    )
                                    dialogStore.setState({
                                        dialogLines: [
                                            ...dialogStore
                                                .getState()
                                                .dialogLines.slice(0, index),
                                            line,
                                            ...dialogStore
                                                .getState()
                                                .dialogLines.slice(index + 1),
                                        ],
                                    })
                                },
                            }}
                            rpDialogOutputTop={{
                                className: `dialogue-output-top margin-bottom margin-xxsmall max-width-full ${index % 2 !== 0 && 'reverse-row'}`,
                            }}
                            slotTemperatureRange={
                                <SliderRange
                                    max={200}
                                    // value={line.llama_generation_params.temperature * 100}
                                    value={Math.round(
                                        transformValue(
                                            line?.llama_generation_params.temperature || 0,
                                            0,
                                            2,
                                            0,
                                            100
                                        )
                                    )}
                                    onChange={(value: number) => {
                                        let newValue = value / 100
                                        dialogStore.setState({
                                            dialogLines: [
                                                ...dialogStore
                                                    .getState()
                                                    .dialogLines.slice(0, index),
                                                {
                                                    ...line,
                                                    llama_generation_params: {
                                                        ...line.llama_generation_params,
                                                        temperature: newValue,
                                                    },
                                                },
                                                ...dialogStore
                                                    .getState()
                                                    .dialogLines.slice(index + 1),
                                            ],
                                        })
                                        line.llama_generation_params.temperature =
                                            newValue
                                    }}
                                />
                            }
                            slotTokenRange={
                                <SliderRange
                                    value={Math.round(
                                        transformValue(
                                            line?.llama_generation_params.max_tokens || 80,
                                            10,
                                            200,
                                            0,
                                            100
                                        )
                                    )}
                                    onChange={(value: number) => {
                                        // Set the token in the store
                                        dialogStore.setState({
                                            dialogLines: [
                                                ...dialogStore
                                                    .getState()
                                                    .dialogLines.slice(0, index),
                                                {
                                                    ...line,
                                                    llama_generation_params: {
                                                        ...line.llama_generation_params,
                                                        max_tokens: Math.round(
                                                            revertTransform(
                                                                value,
                                                                10,
                                                                200,
                                                                0,
                                                                100
                                                            )
                                                        ),
                                                    },
                                                },
                                                ...dialogStore
                                                    .getState()
                                                    .dialogLines.slice(index + 1),
                                            ],
                                        })
                                        // update the line
                                        line.llama_generation_params.max_tokens =
                                            Math.round(
                                                revertTransform(value, 10, 200, 0, 100)
                                            )
                                    }}
                                />
                            }
                            slotToxicityRange={
                                <SliderRange
                                    value={line?.toxicity * 100 || 0}
                                    onChange={(value: number) => {
                                        let newValue = value / 100
                                        dialogStore.setState({
                                            dialogLines: [
                                                ...dialogStore
                                                    .getState()
                                                    .dialogLines.slice(0, index),
                                                { ...line, toxicity: newValue },
                                                ...dialogStore
                                                    .getState()
                                                    .dialogLines.slice(index + 1),
                                            ],
                                        })
                                        line.toxicity = newValue
                                    }}
                                />
                            }
                            slotGeneratedFrom={
                                <div>
                                    {line.instruction ? (
                                        <span>Generated as Event</span>
                                    ) : (
                                        <span>Generated as Dialog</span>
                                    )}
                                </div>
                            }
                            rpDialogLineRegenerate={{
                                onClick: (e: Event) => {
                                    e.preventDefault()
                                    regenerateLine(
                                        index,
                                        line.llama_generation_params,
                                        line.toxicity,
                                        line.language,
                                        line.instruction
                                    )
                                },
                            }}
                        />
                    )}
                </>
            )}
        </>

    )
}

type DialogGenerateOptionsButtonsContainerProps = {
    isEnabled?: boolean
    selectedCharacters: dialogModel.CharacterState[]
    isPlayer?: boolean
    eventsEmpty?: boolean
    eventsOptions: EventOption[]
    selectedEvents: EventOption | null
    setSelectedEvents: (selectedEvents: EventOption) => void
    handleButtonClick: () => void
    onClick: (numLines: number) => void
}
function DialogGenerateOptionsButtonsContainer({
    isEnabled = false,
    selectedCharacters,
    // isPlayer = false,
    eventsEmpty = false,
    eventsOptions,
    selectedEvents,
    setSelectedEvents,
    handleButtonClick,
    onClick,
}: DialogGenerateOptionsButtonsContainerProps) {
    const { dialogStore } = useContext(DialogContext)
    const [playerActive, setPlayerActive] = useState(false)

    useLayoutEffect(() => {
            if (selectedCharacters && selectedCharacters[0].character?.name === 'Player' || selectedCharacters[1].character?.name === 'Player') {
            setPlayerActive(true)
        } else {
            setPlayerActive(false)
        }
    }, [selectedCharacters])



    return (
        <>
            <DialogGenerateOptionsButtons
                eventsEmpty={eventsEmpty}
                isPlayer={playerActive}
                rpButtonChooseEvents={{
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                    disabled: playerActive,
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        if (selectedEvents) {
                            handleButtonClick()
                        }
                    },
                }}
                rpEventsDropdown={{
                    placeholder: 'Choose your major event...',
                    disabled: playerActive,
                    value: selectedEvents,
                    options: eventsOptions,
                    onChange: (selectedOptions: EventOption) => {
                        setSelectedEvents(selectedOptions)
                    },
                }}
                rpGenerateLeft={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(2)
                    },
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
                rpGenerateMiddle={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(4)
                    },
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
                rpGenerateRight={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(8)
                    },
                    className: `button is-large is-secondary ${playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
                rpGenerateYouAsAPlayer={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        isEnabled && onClick(1)
                    },
                    className: `button is-xlarge is-secondary ${!playerActive ? 'hidden' : isEnabled ? '' : 'is-disabled'}`,
                }}
            />
        </>
    )
}
