import { useMutation } from '@tanstack/react-query'
import { logsModel } from 'entities/loganalysis'
import { queryClient } from 'shared/lib/react-query'
import { StoreApi } from 'zustand'
import { createLogsLineMutation, createLogsUploadMutation } from './logsanalysis.api'
import { LogsLine, LogsUploadPdf } from './logsanalysis.types'

const keys = {
    root: () => ['logsLine'],
    logsLine: () => [...keys.root(), 'logsLine'],
    logsUpload: () => [...keys.root(), 'logsUpload'],
    createLogsUpload: () => [...keys.root(), 'createLogsUpload'] as const,
    createLogsLine: () => [...keys.root(), 'createLogsLine'] as const,
}

// Upload Mutations
export function useLogsUploadMutation() {
    return useMutation({
        mutationKey: keys.createLogsUpload(),
        mutationFn: createLogsUploadMutation,
    })
}

export function useCreateLogsUploadMutation(
    logsStore?: StoreApi<logsModel.State>
) {
    return useMutation({
        mutationKey: keys.createLogsUpload(),
        mutationFn: createLogsUploadMutation,
        onSuccess: (data) => {
            const currentLogsUploadsPDF = logsStore?.getState().logsUploadsPDF || []
            logsStore?.setState({
                logsUploadsPDF: [...currentLogsUploadsPDF, data],
            })
        },
    })
}

export const LogsUploadService = {
    queryKey: () => keys.logsUpload(),

    getCache: () =>
        queryClient.getQueryData<LogsUploadPdf>(LogsUploadService.queryKey()),

    setCache: (logsUpload: LogsUploadPdf) =>
        queryClient.setQueryData(LogsUploadService.queryKey(), logsUpload),

    removeCache: () =>
        queryClient.removeQueries({
            queryKey: LogsUploadService.queryKey(),
        }),
}

/////////

export function useRegenerateLogsLineMutation() {
    return useMutation({
        mutationKey: keys.createLogsLine(),
        mutationFn: createLogsLineMutation,
    })
}

export const LogsLineService = {
    queryKey: () => keys.logsLine(),

    getCache: () =>
        queryClient.getQueryData<LogsLine>(LogsLineService.queryKey()),

    setCache: (LogsLine: LogsLine) =>
        queryClient.setQueryData(LogsLineService.queryKey(), LogsLine),

    removeCache: () =>
        queryClient.removeQueries({
            queryKey: LogsLineService.queryKey(),
        }),
}


type StreamRequestBody = {
    file_logs: string
    llama_load_params: ({
        n_gpu_layers: number
        n_ctx: number
    })
    llama_generation_params: ({
        max_tokens: number
        temperature: number
    })
}

export function useCreateLogsLineMutation(
) {
    return useMutation<
        void,
        Error,
        {
            logsLine: StreamRequestBody
            onChunkReceived: (chunk: string) => void
        }
    >({
        mutationKey: keys.createLogsLine(),
        mutationFn: ({ logsLine, onChunkReceived }) =>
            createLogsLineMutation({
                logsLine,
                onChunkReceived,
            }),
    })
}
