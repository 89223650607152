import React from "react";
import * as _Builtin from "../_Builtin";
import { ButtonIcon } from "../_Component/ButtonIcon";
import { ButtonText } from "../_Component/ButtonText";
import { ButtonTextSmall } from "../_Component/ButtonTextSmall";
import Select from 'react-select'

export function DialogGenerateOptionsButtons({
  as: _Component = _Builtin.Block,
  eventsEmpty = false,
  isPlayer = false,
  rpEventsDropdown = {},
  rpButtonChooseEvents = {},
  rpGenerateYouAsAPlayer = {},
  rpGenerateLeft = {},
  rpGenerateMiddle = {},
  rpGenerateRight = {},
}) {

  const customStyles = {
    control: (provided) => ({
      ...provided,
      marginBottom: '0px',
      borderStyle: 'solid',
      borderWidth: '0.7px',
      borderColor: 'hsla(0, 0%, 100%, 0)',
      borderRadius: '4px',
      backgroundColor: isPlayer ? '#6e6e6e' : '#333a75',
      color: 'white',
      fontSize: '1rem',
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333a75',
      zIndex: 9999,
      width: '450px',
      scrollbarWidth: 'thin',
      scrollbarColor:
        'hsla(0, 0.00%, 0.00%, 0.20) hsla(0, 0.00%, 0.00%, 0.00)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#333a75'
        : state.isFocused
          ? '#404a8e'
          : '#333a75',
      color: state.isSelected ? 'white' : 'white',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      borderWidth: '0.7px',
      color: 'white',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    menuList: (provided) => ({
      ...provided,
      paddingRight: '10px',
    }),
    container: (provided) => ({
      ...provided,
      width: '450px',
      margin: '0 auto', 
    }),
  }


  return (
    <_Component
      id="w-node-_57f6b37a-7b99-b30f-f9c3-ea12f9f55583-f9f55583"
      tag="div"
    >
      {!eventsEmpty && (
        <>
          <_Builtin.Block className="bold margin-bottom margin-xxsmall" tag="div">
          Event Execution
          </_Builtin.Block>
          <Select
            className=" background-blue-150 rounded-8 cursor-pointer text-color-white cursor-pointer"
            {...rpEventsDropdown}
            closeMenuOnSelect={true}
            isDisabled={isPlayer}
            styles={customStyles}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: 'white',
                primary: 'white',
              },
            })}
          />
          {isPlayer && (
          <_Builtin.Block className="flex-center margin-vertical italic text-size-small text-color-grey" tag="div">
            Not available for Custom Player. Choose another character or click on the LLM generation button below.
          </_Builtin.Block>
        )}
          <_Builtin.Block
            className="button-group margin-top margin-xsmall"
            tag="div"
          >
            <_Builtin.Link
              className=""
              button={false}
              block="inline"
              options={{
                href: '#',
              }}
              {...rpButtonChooseEvents}
            >
              <_Builtin.Block className="slot-button" tag="div">
              <ButtonIcon buttonIconText="" />
                <ButtonText buttonText="Trigger Event" />
                <ButtonTextSmall buttonTextSmall="(1 line)" />
              </_Builtin.Block>
            </_Builtin.Link>
          </_Builtin.Block>
          <_Builtin.Block className="flex-center margin-vertical margin-small" tag="div">
            or
          </_Builtin.Block>
        </>
      )}
          <_Builtin.Block className="bold margin-bottom margin-xxsmall" tag="div">
          LLM Text Generation
      </_Builtin.Block>
      <_Builtin.Block className="flex-center ">
        <_Builtin.Link
          className=""
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
          {...rpGenerateYouAsAPlayer}
        >
          <_Builtin.Block className="slot-button" tag="div">
            <ButtonIcon buttonIconText="" />
            <ButtonText buttonText="Player generation" />
            <ButtonTextSmall buttonTextSmall="(1 line)" />
          </_Builtin.Block>
        </_Builtin.Link>
      </_Builtin.Block>
      <_Builtin.Block className="generate-btn-wrapper">
        <_Builtin.Link
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
          {...rpGenerateLeft}
        >
          <_Builtin.Block className="slot-button" tag="div">
            <ButtonIcon buttonIconText="" />
            <ButtonText buttonText="Short conversation generation" />
            <ButtonTextSmall buttonTextSmall="(2 lines)" />
          </_Builtin.Block>
        </_Builtin.Link>
        <_Builtin.Link
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
          {...rpGenerateMiddle}
        >
          <_Builtin.Block className="slot-button" tag="div">
            <ButtonIcon buttonIconText="" />
            <ButtonText buttonText="Medium conversation generation" />
            <ButtonTextSmall buttonTextSmall="(4 lines)" />
          </_Builtin.Block>
        </_Builtin.Link>
        <_Builtin.Link
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
          {...rpGenerateRight}
        >
          <_Builtin.Block className="slot-button" tag="div">
            <ButtonIcon buttonIconText="" />
            <ButtonText buttonText="Long conversation generation" />
            <ButtonTextSmall buttonTextSmall="(8 lines)" />
          </_Builtin.Block>
        </_Builtin.Link>
      </_Builtin.Block>
    </_Component>
  );
}
