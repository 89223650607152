import {
    PopupNotification,
    SectionClosed,
    SectionVoiceBatch,
    SectionVoiceGeneration,
    VgAddBloc,
    VgGenerateLine,
    VgSelectCard,
    VoiceGenerationProcessingStateGenerating,
    VoiceGenerationProcessingStateReady,
} from 'devlink'
import { characterTypes } from 'entities/character'
import { emotionTypes } from 'entities/emotion'
import { voiceTypes } from 'entities/voice'
import { voiceRecordTypes } from 'entities/voice-record'
import { useCreateVoiceRecordMutation } from 'entities/voice-record/voice-record.queries'
import { VoiceContext } from 'pages/voice/voice-page.lib'
import { voiceStore } from 'pages/voice/voice-page.model'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { SliderRange } from 'shared/ui/slider'

import { characterStore } from 'entities/character/character.model'
import { sessionStore } from 'entities/session/session.model'
import { FileState } from 'entities/voice/voice.model'
import {
    useCreateBatchStatus,
    useStartBatchGeneration,
    useTerminateBatchGeneration,
} from 'entities/voice/voice.queries'

type VoiceGenerationContainerProps = {
    characters: characterTypes.Npcs
}

export function VoiceGenerationContainer({
    characters,
}: VoiceGenerationContainerProps) {
    const { voiceStore } = useContext(VoiceContext)
    const { fileStore } = useContext(VoiceContext)
    const [batchSection, setBatchSection] = useState(false)
    const [batchStatus, setBatchStatus] = useState<FileState>({})
    const [batchGenerationInProgress, setBatchGenerationInProgress] =
        useState(false)
    const [file, setFile] = useState<File | null>(
        fileStore.getState().file || null
    )
    const [filename, setFilename] = useState(file?.name || '')
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false)
    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    })
    const [isGenerateEnabled, setIsGenerateEnabled] = useState(false)
    const [isDownloadEnabled, setIsDownloadEnabled] = useState(false)
    const [isTerminateEnabled, setIsTerminateEnabled] = useState(false)
    const [allRecords, setAllRecords] = useState(true)
    const [firstRecord, setFirstRecord] = useState<number | null>(null)
    const [lastRecord, setLastRecord] = useState<number | null>(null)
    const [lastModified, setLastModified] = useState<string | null | undefined>(
        null
    )

    useLayoutEffect(() => {
        voiceStore.getState().load()
        fileStore.getState().load()
        voiceStore.setState({
            selectedEmotion: { id: 0, name: 'None' },
        })
    }, [])

    const { mutate: createVoiceStatus, isError: isErrorCreateVoiceStatus } =
        useCreateBatchStatus(fileStore)

    const {
        mutate: startBatchGeneration,
        isError: isErrorStartBatchGeneration,
    } = useStartBatchGeneration(fileStore)

    const {
        mutate: terminateBatchGeneration,
        isError: isErrorTerminatingBatchGeneration,
    } = useTerminateBatchGeneration(fileStore)

    useLayoutEffect(() => {
        if (
            !isGenerateEnabled &&
            (isErrorStartBatchGeneration ||
                isErrorCreateVoiceStatus ||
                isErrorTerminatingBatchGeneration)
        ) {
            openPopup(
                '\ue91e',
                'Error starting batch generation',
                ('Error Message: ' + batchStatus?.message) as string
            )
        } else if (isGenerateEnabled && isErrorStartBatchGeneration) {
            openPopup(
                '\ue91e',
                'Error starting batch generation',
                ('Error Message: ' + batchStatus?.message) as string
            )
        }
        // }, [isGenerateEnabled])
    }, [
        isErrorStartBatchGeneration,
        isErrorCreateVoiceStatus,
        isErrorTerminatingBatchGeneration,
    ])

    useLayoutEffect(() => {
        if (file && file !== null && file instanceof File) {
            fileStore.getState().setFile(file)
            setFilename(file.name)
            setLastModified(null)
            if (batchStatus.uuid) {
                createVoiceStatus({
                    file: null,
                    uuid: batchStatus.uuid,
                })
            } else {
                createVoiceStatus({
                    file: file,
                    uuid: null,
                })
            }
        }
    }, [file])

    useLayoutEffect(() => {
        if (batchSection) {
            setAllRecords(true)
        }
    }, [batchSection])

    // subscribe to file store and set batch status if it changes
    useLayoutEffect(() => {
        fileStore.subscribe((state) => {
            setBatchStatus(state)

            if (state.status === 'starting') {
                setBatchGenerationInProgress(true)
            } else {
                setBatchGenerationInProgress(false)
            }
        })
    }, [])

    // if the batch generation is in progress, poll the API for the status
    useEffect(() => {
        if (batchGenerationInProgress) {
            const interval = setInterval(() => {
                if (batchStatus.uuid) {
                    createVoiceStatus({
                        file: null,
                        uuid: batchStatus.uuid,
                    })
                } else {
                    createVoiceStatus({
                        file: file,
                        uuid: null,
                    })
                }
            }, 10000)
            return () => clearInterval(interval)
        }
    }, [batchGenerationInProgress])

    useEffect(() => {
        if (batchStatus?.progress === '100%') {
            setIsDownloadEnabled(true)
            if (
                batchStatus.last_modified &&
                lastModified &&
                batchStatus.last_modified !== lastModified
            ) {
                openPopup(
                    '\ue917',
                    'Finished batch generation',
                    'You can now download the generated voice lines.'
                )
            }
            setLastModified(batchStatus.last_modified)
        } else {
            setIsDownloadEnabled(false)
        }

        if (
            batchStatus.progress &&
            Number(batchStatus.progress.split('%')[0]) >= 0 &&
            Number(batchStatus.progress.split('%')[0]) < 100 &&
            batchStatus.status === 'starting'
        ) {
            setIsTerminateEnabled(true)
            setIsGenerateEnabled(false)
        } else {
            setIsTerminateEnabled(false)
            setIsGenerateEnabled(true)
        }
    }, [batchStatus])

    // save the voice store when the component unmounts
    useLayoutEffect(() => {
        return () => {
            voiceStore.getState().save()
            fileStore.getState().save()
        }
    }, [])

    useLayoutEffect(() => {
        if (file) {
            fileStore.getState().setFile(file)

            // call the API to get the state of the batch generation
        }
    }, [file])

    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        })
        setIsModalPopupOpen(true)
    }

    return (
        <>
            {batchSection && sessionStore.getState().keys?.ariel.batch === 1 ? (
                <>
                    <SectionClosed
                        rpButtonOpen={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                setBatchSection(false)
                            },
                        }}
                        rpIcon={'\ue902'}
                        title={'Single Voice Generation'}
                        subtitle={
                            'Generate voice lines for specific characters'
                        }
                    />
                    <SectionVoiceBatch
                        rpAllRecords={{
                            checked: { allRecords },
                            onChange: (e: Event) => {
                                e.preventDefault()
                                const target = e.target as HTMLInputElement
                                setAllRecords(target.checked)
                            },
                            allRecords: { allRecords },
                        }}
                        rpFilename={filename}
                        rpSetFirstRecord={{
                            onKeyUp: (e: Event) => {
                                e.preventDefault()
                                const target = e.target as HTMLInputElement
                                const inputValue = target.value
                                const numericValue = parseInt(inputValue, 10)
                                if (!isNaN(numericValue)) {
                                    setFirstRecord(numericValue)
                                } else if (inputValue === '') {
                                    setFirstRecord(null)
                                }
                            },
                        }}
                        rpSetLastRecord={{
                            onKeyUp: (e: Event) => {
                                e.preventDefault()
                                const target = e.target as HTMLInputElement
                                const inputValue = target.value
                                const numericValue = parseInt(inputValue, 10)
                                if (!isNaN(numericValue)) {
                                    setLastRecord(numericValue)
                                } else if (inputValue === '') {
                                    setLastRecord(null)
                                }
                            },
                        }}
                        rpFileUpload={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                // open a file browser to select a file
                                const input = document.createElement('input')
                                input.type = 'file'
                                input.accept = '.csv, .xlsx, .xls'; 
                                input.onchange = (e) => {
                                    const target = e.target as HTMLInputElement
                                    if (target.files) {
                                        const fileExtension =
                                            target.files[0].name
                                                .split('.')
                                                .pop()
                                        if (
                                            fileExtension === 'csv' ||
                                            fileExtension === 'xlsx' ||
                                            fileExtension === 'xls'
                                        ) {
                                            openPopup(
                                                '\ue917',
                                                'File uploaded successfully',
                                                'You can now generate voice lines for the characters in the file.'
                                            )
                                            setFile(target.files[0])
                                        } else {
                                            openPopup(
                                                '\ue91e',
                                                'Invalid file type',
                                                'Choose a file with a .csv, .xlsx, or .xls extension.'
                                            )
                                        }
                                    }
                                }
                                input.click()
                            },
                            onDragOver: (e: Event) => {
                                e.preventDefault()
                            },
                            onDrop: (e: Event) => {
                                e.preventDefault()
                                const target = e as DragEvent
                                if (target.dataTransfer) {
                                    const file = target.dataTransfer.files[0]
                                    const fileExtension = file.name
                                        .split('.')
                                        .pop()
                                    if (
                                        fileExtension === 'csv' ||
                                        fileExtension === 'xlsx' ||
                                        fileExtension === 'xls'
                                    ) {
                                        openPopup(
                                            '\ue917',
                                            'File uploaded successfully',
                                            'You can now generate voice lines for the characters in the file.'
                                        )
                                        setFile(file)
                                    } else {
                                        openPopup(
                                            '\ue91e',
                                            'Invalid file type',
                                            'Choose a file with a .csv, .xlsx, or .xls extension.'
                                        )
                                    }
                                }
                            },
                        }}
                        rpFileDelete={{
                            onClick: (e: Event) => {
                                e.preventDefault()
                                setFile(null)
                                setFilename('')
                                setBatchStatus({})
                                fileStore.getState().reset()
                                setAllRecords(true)
                            },
                        }}
                        rpStatus={batchStatus}
                        rpBatchGeneration={{
                            onClick: (e: Event) => {
                                isGenerateEnabled &&
                                    startBatchGeneration({
                                        file: file,
                                        line_start: allRecords
                                            ? null
                                            : firstRecord,
                                        line_end: allRecords
                                            ? null
                                            : lastRecord,
                                    })
                                // set batchstatus progress to 0%
                                setBatchStatus({
                                    ...batchStatus,
                                    progress: '0%',
                                })
                                setBatchGenerationInProgress(true)
                            },
                            className: `button is-secondary-light ${isGenerateEnabled ? '' : 'is-disabled'}`,
                            text: !isGenerateEnabled
                                ? 'Processing...'
                                : 'Batch Generation...',
                        }}
                        rpFileDownload={{
                            onClick: (e: Event) => {
                                if (batchStatus?.url && isDownloadEnabled) {
                                    const a = document.createElement('a')
                                    a.href = batchStatus.url
                                    a.download = batchStatus.url
                                        .split('/')
                                        .pop() as string
                                    a.style.display = 'none'
                                    document.body.appendChild(a)
                                    a.click()
                                    document.body.removeChild(a)
                                }
                            },
                            className: `button is-secondary-light ${isDownloadEnabled ? '' : 'is-disabled'}`,
                        }}
                        rpBatchTerminate={{
                            onClick: (e: Event) => {
                                isTerminateEnabled &&
                                    (batchStatus.uuid
                                        ? terminateBatchGeneration({
                                              file: null,
                                              uuid: batchStatus.uuid,
                                          })
                                        : terminateBatchGeneration({
                                              file: file,
                                              uuid: null,
                                          }))
                                setIsTerminateEnabled(false)
                            },
                            className: `button is-small is-red ${isTerminateEnabled ? '' : 'is-small is-disabled'}`,
                            text:
                                isTerminateEnabled || isGenerateEnabled
                                    ? 'Terminate Process'
                                    : 'Terminating...',
                        }}
                        rpProgressBarVisible={batchGenerationInProgress}
                    />
                </>
            ) : (
                <>
                    <SectionVoiceGeneration
                        slotCharacterVoiceCard={
                            <>
                                <SectionVoiceNpcSelectCard
                                    characters={characters}
                                />
                            </>
                        }
                        slotVoiceCards={
                            <>
                                <SectionVoiceGenerationCards />
                            </>
                        }
                    />
                    {sessionStore.getState().keys?.ariel.batch === 1 && (
                        <SectionClosed
                            rpButtonOpen={{
                                onClick: (e: Event) => {
                                    e.preventDefault()
                                    setBatchSection(true)
                                },
                            }}
                            rpIcon={'\ue902'}
                            title={'Batch Generation'}
                            subtitle={'Generate multiple voice lines at once'}
                        />
                    )}
                </>
            )}
            <PopupNotification
                visibility={isModalPopupOpen}
                rpClosePopUp={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault()
                        setIsModalPopupOpen?.(false)
                    },
                }}
                rpInfoText={{
                    header: popupElements.header,
                    body: popupElements.body,
                    footer: '',
                }}
                rpIcon={popupElements.icon}
            />
        </>
    )
}

type VoiceNpcSelectCardContainerProps = {
    characters: characterTypes.Npcs
}

async function checkFileExists(filePath: string) {
    try {
        const response = await fetch(filePath)

        // The fetch was successful, so the file likely exists
        if (response.status === 200) {
            const contentType = response.headers.get('content-type')

            // If the content type is what you expect, the file likely exists
            if (contentType && contentType !== 'text/html; charset=utf-8') {
                return true
            }
        }

        // The fetch was not successful, so the file likely does not exist
        return false
    } catch (error) {
        // An error occurred while trying to fetch the file, so it likely does not exist
        return false
    }
}

function SectionVoiceNpcSelectCard(props: VoiceNpcSelectCardContainerProps) {
    const [characterImage, setCharacterImage] = useState<string | null>(null)
    const [selectedTeam, setSelectedTeam] = useState<string | null | undefined>(
        characterStore.getState().currentTeam?.name || null
    )
    const { characters } = props
    const { voiceStore } = useContext(VoiceContext)
    const [selectedCharacter, setSelectedCharacter] = useState<
        characterTypes.Npc | null | undefined
    >(null)
    const [selectedEmotion, setSelectedEmotion] = useState<
        emotionTypes.Emotion | null | undefined
    >(null)
    const [audioSample, setAudioSample] = useState('')
    const [audioSampleExists, setAudioSampleExists] = useState(true)
    const defaultEmotion = {
        t: 'neutral',
        v: '0',
    }
    const [emotionOptions, setEmotionOptions] = useState([defaultEmotion])
    const defaultValue = {
        t: 'Choose your NPC',
        v: '',
    }
    const characterOptions = [
        defaultValue,
        ...characters?.map((character) => ({
            t: character?.name,
            v: character?.id.toString(),
        })),
    ]

    useLayoutEffect(() => {
        setSelectedCharacter(voiceStore.getState().selectedCharacter)
        setSelectedEmotion(voiceStore.getState().selectedEmotion)
    }, [])

    useLayoutEffect(() => {
        characterStore.subscribe((state) => {
            if (selectedTeam && state.currentTeam?.name !== selectedTeam) {
                setCharacterImage(null)
                setSelectedTeam(state.currentTeam?.name)
                setSelectedCharacter(null)
                setCharacterImage(null)
                voiceStore.getState().reset()
            }
        })
    }, [selectedTeam])

    useEffect(() => {
        if (selectedCharacter) {
            checkFileExists(
                `/voice-samples/${selectedCharacter.name}.wav`
            ).then((exists) => {
                setAudioSampleExists(exists)
            })

            const currentState = characterStore.getState().images
            const characterImage = currentState?.find(
                (image) => image.name === selectedCharacter.name
            )
            setCharacterImage(characterImage?.image || null)
            setEmotionOptions(
                selectedCharacter.emotion.map((emotion, index) => ({
                    t: emotion,
                    v: index.toString(),
                }))
            )
        } else {
            setCharacterImage(null)
            setEmotionOptions([defaultEmotion])
        }
    }, [selectedCharacter])

    useLayoutEffect(() => {
        if (!selectedCharacter) return
        setAudioSample(`/voice-samples/${selectedCharacter.name}.wav`)
    }, [selectedCharacter])

    const audioRef = useRef<HTMLAudioElement>(null)

    function playSample() {
        if (!audioRef.current || audioSample === '') return

        if (audioRef.current.paused) {
            audioRef.current.currentTime = 0
            audioRef.current.play()
        } else {
            audioRef.current.pause()
        }
    }

    return (
        <>
            <VgSelectCard
            characterImage={
                selectedCharacter &&
                selectedCharacter.name === 'Player'
                ? '/images/blank.png'
                : characterImage || '/images/blank.png'
            }
            rpDropdownCharacter={{
                label: 'Choose your NPC',
                options: characterOptions.filter(
                (character) => character.t !== 'Player'
                ),
                value: selectedCharacter?.id.toString() || '',
                name: selectedCharacter?.name,
                onChange: (e: Event) => {
                const target = e.target as HTMLSelectElement
                const selectedCharacter = characters.find(
                    (character) =>
                    character.id.toString() === target.value
                )
                setSelectedCharacter(selectedCharacter || null)
                voiceStore.setState({
                    selectedCharacter: selectedCharacter,
                })

                if (selectedCharacter) {
                    setSelectedEmotion(null)
                    voiceStore.setState({
                    selectedEmotion: {
                        id: 0,
                        name: 'None',
                    },
                    })
                }
                },
            }}
            rpDropdownEmotion={{
                label: 'Choose an emotion',
                options: emotionOptions,
                value: selectedEmotion?.id.toString() || '',
                onChange: (e: Event) => {
                const target = e.target as HTMLSelectElement
                const selectedEmotion = emotionOptions.find(
                    (emotion) => emotion.v === target.value
                )
                setSelectedEmotion(
                    {
                    id: Number(selectedEmotion?.v),
                    name: selectedEmotion?.t || 'None',
                    } 
                )
                voiceStore.setState({
                    selectedEmotion:
                    {
                        id: Number(selectedEmotion?.v),
                        name: selectedEmotion?.t || 'None',
                    } 
                })
                },
            }}
            rpButtonPlaySample={{
                onClick: (e: Event) => {
                e.preventDefault()
                playSample()
                },
                state: audioRef.current,
            }}
            // emotionSpeaker={emotions?.speakers}
            audioSampleExists={audioSampleExists}
            emotions={selectedCharacter?.emotion}
            />
            <audio ref={audioRef} src={audioSample} />
        </>
    )
}

function SectionVoiceGenerationCards(
    {
        // characters,
        // voices,
    }: {
        // characters: characterTypes.Npcs
        // voices: voiceTypes.Voices
    }
) {
    const { voiceStore } = useContext(VoiceContext)
    const [voiceRecords, setVoiceRecords] = useState<
        voiceRecordTypes.VoiceRecord[]
    >(voiceStore.getState().voiceRecords || [])
    const [selectedCharacter, setSelectedCharacter] = useState<
        characterTypes.Npc | null | undefined
    >(null)
    const [selectedEmotion, setSelectedEmotion] = useState({
        id: 0,
        name: 'None',
    })
    const [isNpcCardFilled, setIsNpcCardFilled] = useState(
        voiceStore.getState().selectedCharacter != null
    )

    useLayoutEffect(() => {
        // subscribe to the voice store and update the voice records
        voiceStore.subscribe((state) => {
            setSelectedEmotion(
                voiceStore.getState().selectedEmotion || { id: 0, name: 'None' }
            )
            setVoiceRecords(state.voiceRecords || [])
            setSelectedCharacter(state.selectedCharacter)
        })
    }, [])

    useLayoutEffect(() => {
        setIsNpcCardFilled(selectedCharacter != null)
        if (!selectedCharacter) {
            voiceStore.getState().reset()
        }
    }, [selectedCharacter])

    return (
        <>
            {voiceRecords?.map((voiceRecord, index) => {
                return (
                    <VoiceGenerationCard
                        // key={index}
                        index={index}
                        voiceRecord={voiceRecord}
                        isNpcCardFilled={isNpcCardFilled}
                        // characters={characters}
                        // voices={voices}
                    />
                )
            })}
            <VgAddBloc
                rpAddBlocButton={{
                    onClick: (e: Event) => {
                        e.preventDefault()
                        voiceStore.getState().addVoiceRecord({
                            sentence: '',
                            convergence: 0.5,
                            speed: 1,
                            emotion: selectedEmotion?.name || undefined,
                        })
                    },
                }}
            />
        </>
    )
}

type VoiceGenerationCardProps = {
    index: number
    voiceRecord: voiceRecordTypes.VoiceRecord
    isNpcCardFilled: boolean
    // emotions: emotionTypes.Emotions
    // characters: characterTypes.Npcs
    // voices: voiceTypes.Voices
}
function VoiceGenerationCard(props: VoiceGenerationCardProps) {
    const {
        index,
        voiceRecord,
        isNpcCardFilled,
        // characters,
        // voices,
    } = props

    const [voiceRecordText, setVoiceRecordText] = useState(voiceRecord.sentence)
    const [selectedEmotion, setSelectedEmotion] = useState(
        voiceStore.getState().selectedEmotion
    )
    const [voiceRecordEmotion, setVoiceRecordEmotion] = useState(
        voiceRecord.emotion
    )
    const [voiceRecordConvergence, setVoiceRecordConvergence] = useState(
        voiceRecord?.convergence || 0.1
    )
    const [voiceRecordSpeed, setVoiceRecordSpeed] = useState(
        voiceRecord?.speed || 1
    )
    const [audioUrl, setAudioUrl] = useState(voiceRecord.audio || '')
    const audioRef = useRef<HTMLAudioElement>(null)
    const [isGenerateButtonEnabled, setIsGenerateButtonEnabled] =
        useState(false)
    const [selectedLanguage, setSelectedLanguage] =
        useState<voiceTypes.Language>(
            voiceStore.getState().selectedLanguage || { id: 0, name: 'English' }
        )
    const [languageOptions, setLanguageOptions] = useState<any[]>([])
    const {
        mutate: createVoiceRecord,
        isPending: isCreatingVoiceRecord,
        isSuccess: isVoiceRecordCreated,
        isError: isVoiceRecordError,
        error: isVoiceRecordErrorData,
        data: voiceRecordData,
        reset: resetVoiceRecord,
    } = useCreateVoiceRecordMutation(voiceStore)
    const [isPlaying, setIsPlaying] = useState(false)
    const [selectedCharacter, setSelectedCharacter] = useState<
        characterTypes.Npc | null | undefined
    >(voiceStore.getState().selectedCharacter)

    useLayoutEffect(() => {
        voiceStore.subscribe((state) => {
            setSelectedEmotion(state.selectedEmotion)
            if (state.selectedCharacter) {
                setSelectedCharacter(state.selectedCharacter)
            } else {
                setSelectedCharacter(null)
            }
            if (state.selectedCharacter?.language) {
                const languageOptions = [
                    { t: 'English', v: '0' },
                    ...state.selectedCharacter.language.map(
                        (language, index) => ({
                            t: language,
                            v: (index + 1).toString(),
                        })
                    ),
                ]

                for (let i = languageOptions.length - 1; i >= 0; i--) {
                    if (languageOptions[i].t === 'English') {
                        languageOptions.splice(i, 1)
                        break
                    }
                }
                //I want language to be by default on English if the list contains it

                languageOptions.sort((a, b) => a.t.localeCompare(b.t));

                setLanguageOptions(languageOptions)

                const englishOption = languageOptions.find(option => option.t === 'English');
                
            if (englishOption) {
                setSelectedLanguage({
                    id: Number(englishOption.v),
                    name: englishOption.t,
                });
            } else {

                setSelectedLanguage({
                    id: Number(languageOptions[0].v),
                    name: languageOptions[0].t,
                });
            }
        
            }
        })
    })

    useLayoutEffect(() => {
        setIsGenerateButtonEnabled(
            isNpcCardFilled &&
                !!voiceRecordText &&
                !Number.isNaN(voiceRecordConvergence) &&
                !Number.isNaN(voiceRecordSpeed) &&
                !isCreatingVoiceRecord
        )
    }, [
        voiceRecordText,
        voiceRecordConvergence,
        voiceRecordSpeed,
        isNpcCardFilled,
        isCreatingVoiceRecord,
    ])

    useLayoutEffect(() => {
        voiceStore.subscribe((state) => {
            if (state.voiceRecords && state.voiceRecords[index]) {
                setVoiceRecordText(state.voiceRecords[index].sentence)
                setVoiceRecordSpeed(state.voiceRecords[index].speed)
                setVoiceRecordEmotion(state.voiceRecords[index].emotion)
                if (selectedCharacter !== state.selectedCharacter) {
                    // setSelectedLanguage({ id: 0, name: 'English' })
                    setSelectedCharacter(state.selectedCharacter)
                }
                // else {
                //     setSelectedLanguage(
                //         state.selectedLanguage || { id: 0, name: 'English' }
                //     )
                // }
            }
        })
    }, [index, voiceRecord])

    useLayoutEffect(() => {
        if (isVoiceRecordCreated) {
            voiceStore.getState().updateVoiceRecord(index, {
                sentence: voiceRecordData.sentence,
                convergence: voiceRecordData.convergence,
                emotion: voiceRecordData.emotion,
                audio: voiceRecordData.audio,
                length: voiceRecordData.length,
                speed: voiceRecordData.speed,
            })
            setAudioUrl(voiceRecordData.audio || '')
        }
    }, [isVoiceRecordCreated, voiceRecordData])

    useLayoutEffect(() => {
        if (voiceRecord) {
            voiceStore.getState().updateVoiceRecord(index, {
                sentence: voiceRecord.sentence,
                convergence: voiceRecord.convergence,
                speed: voiceRecord.speed,
                emotion: selectedEmotion?.name,
            })
        }
    }, [selectedEmotion])

    function playAudio() {
        if (!audioRef.current || audioUrl === '') return
        console.log("State", audioRef.current, isPlaying)
        if (audioRef.current.paused) {
            audioRef.current.currentTime = 0
            setIsPlaying(true)
            audioRef.current.play()

            // set a timeout for audioRef.current.duration (seconds) and setIsPlaying=False
            setTimeout(() => {
                setIsPlaying(false)
            }, audioRef.current.duration * 1000)
        } else {
            setIsPlaying(false)
            audioRef.current.pause()
        }
    }

    function downloadAudio() {
        if (!audioRef.current || audioUrl === '') return
        if (audioRef.current) {
            const a = document.createElement('a')
            a.href = audioRef.current.src
            window.open(a.href, '_blank')
            a.download = `${voiceStore.getState().selectedCharacter?.name || 'audio'}-voice-record.wav`
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        }
    }

    useEffect(() => {
        if (selectedCharacter && languageOptions.length > 0) {
            setSelectedLanguage({ id: parseInt(languageOptions[0].v), name: languageOptions[0].t })
            voiceStore.setState({
                selectedLanguage: { id: parseInt(languageOptions[0].v), name: languageOptions[0].t },
            })
        }
    }, [selectedCharacter])

    return (
        <>
            <VgGenerateLine
                slotVariabilityRange={
                    <SliderRange
                        value={voiceRecordConvergence * 100}
                        onChange={(value: number) => {
                            setVoiceRecordConvergence(value / 100)
                        }}
                    />
                }
                rpButtonDelete={{
                    className: `button is-icon is-square ${voiceRecordText === '' && audioUrl === '' ? 'is-disabled' : ''}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        if (voiceRecordText === '' && audioUrl === '') return
                        voiceStore.getState().removeVoiceRecord(index)
                        setAudioUrl('')
                        resetVoiceRecord()
                    },
                }}
                rpVoiceGenerationText={{
                    value: voiceRecord.sentence,
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const updatedSentence = e.target.value
                        voiceStore.getState().updateVoiceRecord(index, {
                            sentence: updatedSentence,
                            convergence: voiceRecord.convergence,
                            speed: voiceRecord.speed,
                            emotion: voiceRecord.emotion,
                        })
                    },
                }}
                rpButtonGenerate={{
                    className: `button is-primary ${isGenerateButtonEnabled ? '' : 'is-disabled'}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        const voiceState = voiceStore.getState()
                        const characterState = characterStore.getState()
                        isGenerateButtonEnabled &&
                            createVoiceRecord({
                                voiceRecord: {
                                    sentence: voiceRecordText,
                                    convergence: 1 - voiceRecordConvergence,
                                    speed: voiceRecordSpeed,
                                    emotion:
                                        voiceRecordEmotion === 'None'
                                            ? undefined
                                            : voiceRecordEmotion,
                                },
                                language: selectedLanguage.name,
                                npcName:
                                    characterState.npcs?.find(
                                        (npc) =>
                                            npc.id ===
                                            voiceState.selectedCharacter?.id
                                    )?.ariel_name || '',
                            })
                    },
                }}
                slotGenerationProcessingState={
                    <>
                        {isCreatingVoiceRecord && (
                            <VoiceGenerationProcessingStateGenerating />
                        )}
                        {audioUrl !== '' && !isCreatingVoiceRecord && (
                            <VoiceGenerationProcessingStateReady />
                        )}
                    </>
                }
                rpButtonPlay={{
                    className: `button is-secondary ${audioUrl === '' ? 'is-disabled' : ''}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        audioUrl !== '' && playAudio()
                    },
                    state: isPlaying,
                }}
                rpButtonDownload={{
                    className: `button is-icon is-square ${audioUrl === '' ? 'is-disabled' : ''}`,
                    onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault()
                        audioUrl !== '' && downloadAudio()
                    },
                }}
                showEmotionTag={
                    selectedCharacter && selectedCharacter?.emotion.length > 1
                        ? 'flex-center'
                        : 'flex-center hidden'
                }
                rpDropdownLanguage={{
                    label: 'Choose a language',
                    options: languageOptions,
                    value:
                        selectedLanguage?.id.toString() || 'Choose a language',
                    onChange: (e: Event) => {
                        const target = e.target as HTMLSelectElement
                        const selectedLanguage = languageOptions.sort((a, b) => a.t.localeCompare(b.t)).find(

                            (language) => language.v === target.value
                        )
                        voiceStore.setState({
                            selectedLanguage: {
                                id: parseInt(target.value),
                                name: selectedLanguage?.t || 'English',
                            },
                        })
                        setSelectedLanguage({
                            id: parseInt(target.value),
                            name: selectedLanguage?.t || 'English',
                        })
                    },
                }}
                emotions={selectedCharacter?.emotion}
            />
            <audio ref={audioRef} src={audioUrl} />
            {isVoiceRecordError && (
                <PopupNotification
                    visibility={isVoiceRecordError}
                    rpClosePopUp={{
                        onClick: (e: React.MouseEvent) => {
                            e.preventDefault()
                            resetVoiceRecord()
                        },
                    }}
                    rpInfoText={{
                        header: 'Error generating voice line',
                        body:
                            JSON.parse(
                                (isVoiceRecordErrorData as any)?.response
                            )?.detail?.error ||
                            'An error occurred while generating the voice line.',
                        footer: '',
                    }}
                    rpIcon={'\ue91e'}
                />
            )}
        </>
    )
}
