import { GamehelperParams, PopupNotification } from 'devlink';
import {
    useCreateGamehelperLineMutation,
    useCreateGamehelperUploadMutation,
} from 'entities/gamehelper/gamehelper.queries';
import { GamehelperContext } from 'pages/gamehelper/gamehelper-page.model';
import React, { useContext, useEffect, useState } from 'react';

type GamehelperParamsContainerProps = {
    isLoading?: boolean,
};

export function GamehelperParamsContainer({
    isLoading,
}: GamehelperParamsContainerProps) {

    interface GamehelperUploadInput {
        gamehelperUploadPDF: {
            file: File;
            best_response: string;
            scanned_PDF: boolean;
        };
    }

    interface GamehelperLineInput {
        gamehelperLine: {
            file_name: string;
            question: string;
            best_response: string;
        };
    }

    const [popupElements, setPopupElements] = useState({
        header: '',
        body: '',
        icon: '',
    });
    const [isModalPopupOpen, setIsModalPopupOpen] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileContent, setFileContent] = useState<string | null>(null);
    const [question, setQuestion] = useState<string>('');
    const [answer, setAnswer] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string>(''); 
    const [displayAnswer, setDisplayAnswer] = useState(false);
    const [questionChanged, setQuestionChanged] = useState(false); 
    const [containsImage, setContainsImage] = useState(false); 
    const { gamehelperStore } = useContext(GamehelperContext);

    let gamehelperResponse = "";

    const {
        mutate: createGamehelperLine,
        isPending: isGeneratingLine,
        isSuccess: isSuccessCreateLine,
        isError: isErrorCreateLine,
        data: dataCreateLine,
        error: errorCreateLine,
    } = useCreateGamehelperLineMutation();

    const {
        mutate: createGamehelperUpload,
        isSuccess: isSuccessCreateUpload,
    } = useCreateGamehelperUploadMutation(gamehelperStore);

    
    const openPopup = (icon: string, title: string, message: string) => {
        setPopupElements({
            header: title,
            body: message,
            icon: icon,
        });
        setIsModalPopupOpen(true);
    };

    const handleFileUpload = (uploadedFile: File) => {
        const fileExtension = uploadedFile.name.split('.').pop();
        const allowedExtensions = ['pdf', 'doc', 'docx', 'txt'];
    
        if (fileExtension && allowedExtensions.includes(fileExtension)) {
            setFile(uploadedFile);
            setFileName(uploadedFile.name); 

            createGamehelperUpload(
                { 
                    gamehelperUploadPDF: {
                        file: uploadedFile,
                        best_response: '',
                        scanned_PDF: containsImage
                    }
                },
                {
                    onSuccess: (data) => {
                        setFileContent(data?.best_response || ""); 
                        openPopup('\ue902', 'File uploaded successfully', 'You can now ask your question.');
                    },
                    onError: (error) => {
                        openPopup('\ue91e', 'Upload Error', 'Failed to upload the file. Please try again.');
                    }
                }
            );
        } else {
            openPopup('\ue91e', 'Invalid file type', 'Choose a valid file type.');
        }
    };

    const generateAnswer = async () => {
        gamehelperResponse = "";
        setAnswer("");
        if (fileName && question) { 
            try {
                const fileNameWithoutExtension = fileName.replace(/\.(pdf|txt|doc|docx)$/, ''); 
                createGamehelperLine(
                    { 
                        gamehelperLine: {
                            file_name: fileNameWithoutExtension, 
                            is_corporate: false,
                            question: question,
                        },
                        onChunkReceived: (chunk: string) => {
                            // update gamehelperStore with the generated answer
                            gamehelperStore.setState((state) => ({
                                ...state,
                                gamehelperLines: [
                                    ...state.gamehelperLines,
                                    {
                                        file_name: fileNameWithoutExtension,
                                        question: question,
                                        is_corporate: false,
                                        response: state.gamehelperLines[0] + chunk,
                                    },
                                ],
                            }));
                            gamehelperResponse += chunk;
                            setAnswer(gamehelperResponse);
                            setDisplayAnswer(true);
                            setQuestionChanged(false);
                        }
                    }
                );
            } catch (error) {
                openPopup('\ue91e', 'Error', 'An error occurred while generating the answer.');
            }
        } else {
            openPopup('\ue91e', 'Incomplete Data', 'Please ensure a file is uploaded and a question is provided.');
        }
    };

    useEffect(() => {
        if (isSuccessCreateLine) {
            // setAnswer(gamehelperResponse);
            console.log('Answer generated successfully', answer);
        } 
        if (isErrorCreateLine) {
            console.log('Error generating answer', errorCreateLine);
            openPopup('\ue91e', 'Error', 'Please try generating the answer again.');
        }
    }, [isSuccessCreateLine, isErrorCreateLine]);

    const handleDisplayAnswer = () => {
        if (!fileContent) {
            openPopup('\ue91e', 'No file uploaded', 'Please upload a file to generate an answer.');
            return;
        }
        generateAnswer();
    }

    return (
        <>
            <GamehelperParams 
                fileName={fileName}
                isGeneratingLine={isGeneratingLine}
                isSuccessCreateUpload={isSuccessCreateUpload}
                questionChanged={questionChanged}
                question={question}
                containsImage={containsImage} 
                setContainsImage={setContainsImage} 
                rpFileQuestion={{
                    value: question,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        setQuestion(e.target.value);
                        setQuestionChanged(true); 
                    },
                }}
                rpFileUpload={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault();
                        const input = document.createElement('input');
                        input.type = 'file';
                        input.accept = '.pdf, .doc, .docx, .txt';
                        input.onchange = (e) => {
                            const target = e.target as HTMLInputElement;
                            if (target.files) {
                                handleFileUpload(target.files[0]);
                            }
                        };
                        input.click();
                    },
                    onDragOver: (e: React.DragEvent) => {
                        e.preventDefault();
                    },
                    onDrop: (e: React.DragEvent) => {
                        e.preventDefault();
                        const droppedFile = e.dataTransfer.files[0];
                        handleFileUpload(droppedFile);
                    },
                }}
                rpEnterButton={{
                    onClick: (e: React.MouseEvent) => {
                        e.preventDefault();
                        handleDisplayAnswer();
                    },
                }}
                result={
                        answer === ""
                        ? <span className="flex-center justify-center text-color-orange padding-vertical padding-xxlarge text-size-small">Generating answer...</span> 
                        : displayAnswer ? answer : <span className='flex-center justify-center padding-vertical padding-xxlarge text-size-small'>Please upload a file and ask a question to see the answer here.</span> 
                }
                
            />
                                                <PopupNotification
                                                    visibility={isModalPopupOpen}
                                                    rpClosePopUp={{
                                                        onClick: (e: React.MouseEvent) => {
                                                            e.preventDefault()
                                                            setIsModalPopupOpen?.(false)
                                                        },
                                                    }}
                                                    rpInfoText={{
                                                        header: popupElements.header,
                                                        body: popupElements.body,
                                                        footer: '',
                                                    }}
                                                    rpIcon={popupElements.icon}
                                                />
        </>
    );
}