import { HeaderLayout } from 'devlink'
import { characterStore } from 'entities/character/character.model'
import { Npc } from 'entities/character/character.types'
import { dialogModel } from 'entities/dialog'
import { DiagenEvents, sessionStore, StateTags } from 'entities/session/session.model'
import { useLayoutEffect, useState } from 'react'
import {
    DialogGenerate,
    DialogNpcSelect,
    PageHeaderDialog,
    PopupDialogBestPractice,
    PopupDialogExport,
    PopupDialogRegenerate,
    PopupDialogRestart,
    PopupDialogSwap,
} from 'widgets/dialog'
import { DialogProvider, dialogStore } from './dialog-page.model'

export function DialogPage() {
    const [characters, setCharacters] = useState<Npc[]>(characterStore.getState().npcs || [])
    const [tags, setTags] = useState<StateTags>(sessionStore.getState().stateTags || [])
    const [selectedCharacters, setSelectedCharacters] = useState<dialogModel.CharacterState[]>(dialogStore.getState().characterStates)
    const tagsOptions = tags.map((tag) => ({ label: tag, value: tag }))
    const [events, setEvents] = useState<DiagenEvents>(sessionStore.getState().diagenEvents || [])
    const [temperature, setTemperature] = useState(1)
    const [token, setToken] = useState(64)
    const [language, setLanguage] = useState('en')
    const [instruction, setInstruction] = useState('')
    const [isGenerating, setIsGenerating] = useState(false)
    const [toxicity, setToxicity] = useState(0.5)

    useLayoutEffect(() => {
        const unsubscribeCharacterStore = characterStore.subscribe((state) => {
            if (state.npcs) {
                setCharacters(state.npcs)
            }
        })
        
        const unsubscribeDialogStore = dialogStore.subscribe((state) => {
            if (state.characterStates) {
                setSelectedCharacters(state.characterStates)
            }
        })

        const unsubscribeSessionStore = sessionStore.subscribe((state) => {
            if (state.stateTags) {
                setTags(state.stateTags)
            }
        })

        const unsubscribeEventsSessionStore = sessionStore.subscribe((state) => {
            if (state.diagenEvents) {
                setEvents(state.diagenEvents)
            }
        })


        return () => {
            unsubscribeSessionStore()
            unsubscribeDialogStore()
            unsubscribeCharacterStore()
            unsubscribeEventsSessionStore()
        }

    }, [])


    return (
        <DialogProvider>
            <HeaderLayout
                slotHeader={<PageHeaderDialog />}
                slotHeaderLayout={
                    <>
                        <DialogNpcSelect
                            characters={characters}
                            temperature={temperature}
                            token={token}
                            toxicity={toxicity}
                            language={language}
                            instruction={instruction}
                            setInstruction={setInstruction}
                            setTemperature={setTemperature}
                            setToken={setToken}
                            setLanguage={setLanguage}
                            setToxicity={setToxicity}
                            isGenerating={isGenerating}
                            tags={tags}
                            events={events}
                            selectedCharacters={selectedCharacters}
                            />
                        <DialogGenerate
                            characters={characters}
                            tagsOptions={tagsOptions}
                            events={events}
                            temperature={temperature}
                            toxicity={toxicity}
                            token={token}
                            language={language}
                            setIsGenerating={setIsGenerating}
                            selectedCharacters={selectedCharacters}
                        />
                    </>
                }
            />
            <PopupDialogRestart />
            <PopupDialogRegenerate />
            <PopupDialogSwap />
            <PopupDialogBestPractice/>
            <PopupDialogExport />
        </DialogProvider>
    )
}
