import { faCheck, faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as _Builtin from '../_Builtin'
import { ButtonIcon } from '../_Component/ButtonIcon'
import { ButtonText } from '../_Component/ButtonText'
import { useGamehelperContext } from '../../pages/gamehelper/gamehelper-page.model'

export function PageHeaderGamehelper({
    as: _Component = _Builtin.Block,
    pageHeaderTitle = 'Game Helper',
    slotPageHeaderLargeButton,
    slotGenerationProcessing,
    rpButton = {},
    buttonLink = {
        href: '#',
    },
    rpBestButton = {},
    rpTeamsDropdown = {},
    rpCopyKeys = {},
    showCopyPopup,
    showDropdown,
}) {
    const { selectedVideoIndex } = useGamehelperContext()
    const videos = [
        { pdf: '/gamehelper-gdd/skyrim.pdf' },
        { pdf: '/gamehelper-gdd/stardew_valley.pdf' },
        { pdf: '/gamehelper-gdd/eurotruck_simulator.pdf' },
        { pdf: '/gamehelper-gdd/ocarina_of_time.pdf' },
    ]

    return (
        <_Component className="header" tag="div">
            <_Builtin.Block
                className="background-color-blue200 padding-small"
                tag="div"
            >
                <_Builtin.Block className="header-container" tag="div">
                    <_Builtin.Block className="header-left" tag="div">
                        <_Builtin.Heading tag="h1">
                            {pageHeaderTitle}
                        </_Builtin.Heading>

                        <_Builtin.Link
                            className="button"
                            button={false}
                            block="inline"
                            options={{
                                href: '#',
                            }}
                            {...rpBestButton}
                        >
                            <_Builtin.Block className="slot-button" tag="div">
                                <ButtonIcon buttonIconText="" />
                                <ButtonText buttonText="Tutorial" />
                            </_Builtin.Block>
                        </_Builtin.Link>
                        <_Builtin.Block className="button-group" tag="div">
                            <_Builtin.Block
                                className="button is-icon cursor-pointer"
                                tag="div"
                                onClick={() => {
                                    const link = document.createElement('a')
                                    link.href = videos[selectedVideoIndex].pdf
                                    link.download = ''
                                    link.click()
                                }}
                            >
                                <_Builtin.Block
                                    className="slot-button"
                                    tag="div"
                                >
                                    <_Builtin.HtmlEmbed
                                        className="icon_ximm"
                                        value="&#xe908;"
                                    />
                                    <ButtonText buttonText="GDD Template" />
                                </_Builtin.Block>
                            </_Builtin.Block>
                        </_Builtin.Block>
                    </_Builtin.Block>
                    <_Builtin.Block className="header-right" tag="div">
                        {/* <_Builtin.Block
                            className="button is-icon-below right"
                            tag="div"
                            {...rpCopyKeys}
                        >
                            {showCopyPopup ? (
                                <>
                                    <ButtonIcon
                                        buttonIconText={
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="1x"
                                            />
                                        }
                                    />
                                    <ButtonText buttonText={'Copied!'} />
                                </>
                            ) : (
                                <>
                                    <ButtonIcon
                                        buttonIconText={
                                            <FontAwesomeIcon
                                                icon={faKey}
                                                size="1x"
                                            />
                                        }
                                    />
                                    <ButtonText buttonText={'Keys'} />
                                </>
                            )}
                        </_Builtin.Block> */}
                        {slotPageHeaderLargeButton ?? (
                            <>
                                {showDropdown && (
                                    <_Builtin.Block
                                        className="slotpageheadersmallbutton"
                                        tag="div"
                                    >
                                        <_Builtin.FormSelect
                                            className="input background-blue-150 min-h-3-4rem"
                                            name="Teams"
                                            data-name="Teams"
                                            required={false}
                                            multiple={false}
                                            id="Teams-1"
                                            {...rpTeamsDropdown}
                                        />
                                        <_Builtin.Block tag="div">
                                            {slotGenerationProcessing ?? (
                                                <LoaderGenerating />
                                            )}
                                        </_Builtin.Block>
                                    </_Builtin.Block>
                                )}
                            </>
                        )}
                    </_Builtin.Block>
                </_Builtin.Block>
            </_Builtin.Block>
        </_Component>
    )
}
